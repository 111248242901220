import { api } from './index';
const resource = '/views';

export const getAllCameraViews = async (currentPage = 1, pageSize = 10) => {
  try {
    const response = await api.get(`${resource}/`, {
      params: {
        currentPage: currentPage,
        pageSize: pageSize
      }
    });
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
};

export const createCameraView = async (payload) => {
  try {
    console.log('payload', payload);
    const response = await api.post(`${resource}/create`, payload);
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
};

export const getCameraViewById = async (id) => {
  try {
    const response = await api.get(`${resource}/${id}`);
    return response.data.data;
  } catch (e) {
    throw new Error(e);
  }
};

export const editCameraView = async (id, payload) => {
  try {
    const response = await api.put(`${resource}/edit/${id}`, payload);
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
};

export const deleteCameraView = async (id) => {
  try {
    const response = await api.delete(`${resource}/${id}`);
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
};

export const uploadAttachmentToAzure = async (cameraViewId, formData) => {
  try {
    const response = await api.post(`${resource}/${cameraViewId}/upload-attachments`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (e) {
    throw new Error(e.response?.data?.message || e.message);
  }
};

export const getCameraViewAttachments = async (cameraViewId) => {
  try {
    const response = await api.get(`${resource}/${cameraViewId}/attachments`);
    return response.data.data; // Return the attachment data
  } catch (error) {
    console.error('Error fetching attachments:', error);
  }
};

export const deleteAttachment = async (cameraViewId, attachmentId) => {
  try {
    const response = await api.delete(`${resource}/${cameraViewId}/attachments/${attachmentId}`);

    if (response.status !== 200) {
      throw new Error('Failed to delete attachment'); // This ensures it triggers the onError callback
    }

    return response.data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || 'Error deleting attachment');
  }
};
