import { api } from './index';
const resource = "/campaigns"

export const getCampaigns = async ({status}) => {
    try {
        const response = await api.get(`${resource}?status=${status}`)
        return response.data
    } catch (error) {
        throw new Error(error)
    }
}

export const getAllCampaigns = async () => {
    try {
        const response = await api.get(`${resource}/all`)
        return response.data
    } catch (error) {
        throw new Error(error)
    }
}

export const getCampaignById = async (id) => {
    try {
        const response = await api.get(`${resource}/${id}`)
        return response.data
    } catch (error) {
        throw new Error(error)
    }

}

export const createCampaign = async (payload) => {
    try {
        const response = await api.post(`${resource}`, payload)
        return response.data
    } catch (error) {
        throw new Error(error)
    }
}

export const createTaskForCampaign = async ({id, payload}) => {
    try {
        const response = await api.post(`${resource}/create-task/${id}`, payload)
        return response.data
    } catch (error) {
        throw new Error(error)
    }
}

export const updateTask = async ({id, payload}) => {
    try {
        const response = await api.put(`${resource}/update-task/${id}`, payload)
        return response.data
    } catch (error) {
        throw new Error(error)
    }
}

export const addEventToCampaign = async ({id, payload}) => {
    try {
        const response = await api.post(`${resource}/add-event-to-campaign/${id}`, payload)
        return response.data
    } catch (error) {
        throw new Error(error)
    }
}