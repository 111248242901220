import {
  Button,
  Checkbox,
  ChoiceList,
  Divider,
  Spinner,
  TextField
} from '@shopify/polaris';
import { convertKeyToTitle } from '../../utils';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { createNotificationGroup } from '../../api/notification-groups.api';
import { setNotificationGroups } from '../../store/slices/scenarioSlice';

const Step2 = ({ scenarioData, formData, setFormData }) => {
  // const { notificationGroups } = useSelector((state) => state.scenario);
  const [open, setOpen] = useState(false);

  const [newNotificationGroup, setNewNotificationGroup] = useState('');
  const [createNotificationGroupError, setCreateNotificationGroupError] = useState(false);
  const { mutate, isSuccess, isError, data, isPending } = useMutation({
    mutationFn: () => createNotificationGroup(newNotificationGroup)
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSuccess) {
      setOpen(false);
      setNewNotificationGroup('');
      dispatch(setNotificationGroups(data));
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      setCreateNotificationGroupError(true);
    }
  }, []);

  // const showModal = () => {
  //   setOpen(true);
  //   setCreateNotificationGroupError(false);
  // };

  const handleOk = () => {
    mutate();
  };

  const handleCancel = () => {
    setOpen(false);
    setNewNotificationGroup('');
    setCreateNotificationGroupError(false);
  };

  return (
    <div className="mt-16 mx-6">
      <h6 className="font-semibold mb-2">Select Events</h6>
      <div className="mb-6">
        {Object.keys(scenarioData.events).map((event, idx) => {
          return (
            <div key={idx} className="mr-4 inline">
              <Checkbox
                label={convertKeyToTitle(event)}
                checked={(() => {
                  if (event in formData.events) {
                    return formData.events[event];
                  }
                  setFormData({
                    ...formData,
                    events: { ...formData.events, [event]: false }
                  });
                  return false;
                })()}
                onChange={(value) => {
                  let updatedEvents = { ...formData.events, [event]: value };
                  setFormData({ ...formData, events: updatedEvents });
                }}
              />
            </div>
          );
        })}
      </div>
      <Divider />
      <div className="my-6">
        <ChoiceList
          title={<h6 className="font-semibold mb-2">Event Level</h6>}
          choices={[
            { label: 'All', value: 'ALL' },
            { label: 'Critical Only', value: 'CRITICAL_ONLY' },
            { label: 'Recommended', value: 'RECOMMENDED' }
          ]}
          selected={formData.eventLevel}
          onChange={(value) => {
            setFormData({ ...formData, eventLevel: value });
          }}
        />
      </div>
      <Divider />
      <div className="my-6">
        <h6 className="font-semibold mb-2">Time</h6>
        <div className="flex items-center w-full">
          <span className="mr-4">
            <TextField
              label="Start Time"
              value={formData.startTime}
              onChange={(newTime) => setFormData({ ...formData, startTime: newTime })}
              type="time"
            />
          </span>
          <span className="mr-4 mt-7">-</span>

          <span>
            <TextField
              label="End Time"
              value={formData.endTime}
              onChange={(newTime) => setFormData({ ...formData, endTime: newTime })}
              type="time"
            />
          </span>
        </div>
      </div>
      
      {/* Code Commented since option to choose Notification Group(s) has been deprecated.
      The Modal next to it can also be deleted in case we remove this commented code. */}

      {/* <Divider />
      <div className="my-6 w-1/3">
        <ComboBox
          optionsList={notificationGroups}
          label="Choose Notification Group"
          placeholder="Visionify's Group"
          selectedOptions={formData.selectedNotificationGroups}
          setSelectedOptions={(newOptions) => {
            setFormData({ ...formData, selectedNotificationGroups: newOptions });
          }}
          noOptionsConfig={{
            element: (
              <Listbox.Option value={'ADD_NOTIFICATION_GROUP'}>
                <p className="px-4 py-2 cursor-pointer">Add Notification Group</p>
              </Listbox.Option>
            ),
            command: 'ADD_NOTIFICATION_GROUP',
            fn: showModal
          }}
        />
      </div> */}
      <Modal
        title="Add Notification Group"
        open={open}
        onOk={async () => await handleOk()}
        onCancel={handleCancel}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: true }}
        footer={[
          <div className="inline mr-2" key="back">
            <Button variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
          </div>,
          <div className="inline mr-2" key="submit">
            <Button variant="primary" disabled={isPending} onClick={handleOk}>
              <div className="flex items-center">
                <span>Submit</span>{' '}
                {isPending && (
                  <span className="ml-2">
                    <Spinner size="small" color="white" />
                  </span>
                )}
              </div>
            </Button>
          </div>
        ]}>
        <form>
          <TextField
            label="Notification Group Name"
            value={newNotificationGroup}
            onChange={(newValue) => setNewNotificationGroup(newValue)}
          />
          {createNotificationGroupError && (
            <p className="text-red-500 mt-1 text-xs">
              {' '}
              * Unable to create a new notification group! Go to settings and create a new
              notification group!
            </p>
          )}
        </form>
      </Modal>
    </div>
  );
};
export default Step2;
