import { Button } from '@shopify/polaris'
import Error404 from "../../assets/404.svg"
import { useNavigate } from 'react-router-dom';

const NotFound = () => {

    const navigateTo = useNavigate()

    return <div className="w-screen h-screen grid place-items-center">
        <style>
            {`
        .Polaris-Button {
            width: 232px;
            height: 50px;
        }`}
        </style>
        <div className="flex flex-col items-center">
            <img src={Error404} alt="404 Not Found" />
            <h1 className="text-6xl font-bold mb-4">Page Not Found</h1>
            <p className="text-xl text-center mb-10">We&apos;re sorry. The page you requested could not be found. <br />
                Please go back to the home page</p>
            <Button variant='primary' onClick={() => navigateTo("/")}>Back to Home Page</Button>
        </div>

    </div>
}
export default NotFound;