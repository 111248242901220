import { Badge, Button, DataTable, Icon, Spinner, Tooltip } from '@shopify/polaris';
import { EditMajor, CircleInformationMajor } from '@shopify/polaris-icons';
import { useSelector } from 'react-redux';

const truncateUrl = (url) => {
  return url.length > 50 ? `${url.slice(0, 50)}...` : url;
};

const constructRows = (setShowIntegrationModal, setChosenIntegrationData, data, roleId) => {
  const rows = [];
  data.map((integration) => {
    rows.push([
      integration.id,
      integration.name,
      <div key={integration.id} style={{ display: 'flex', alignItems: 'center' }}>
        <span>{truncateUrl(integration.webhook_url)}</span>
        {integration.webhook_url.length > 50 && (
          <Tooltip persistOnClick content={integration.webhook_url}>
            <Icon source={CircleInformationMajor} tone="base" />
          </Tooltip>
        )}
      </div>,
      <Badge key={integration.id} tone={integration.status ? 'success' : 'critical'}>
        {integration.status ? 'Active' : 'Inactive'}
      </Badge>,
      <Button
        key={`button-${integration.id}`}
        disabled={!(roleId === 1 || roleId === 2)}
        onClick={() => {
          setShowIntegrationModal(true);
          setChosenIntegrationData(integration);
        }}
      >
        <Icon source={EditMajor} />
      </Button>
    ]);
  });
  return rows;
};

const IntegrationInfo = ({
  setShowIntegrationModal,
  integrationCallback,
  setChosenIntegrationData
}) => {
  const {user} = useSelector(state => state.auth)
  if (integrationCallback.isLoading) {
    return (
      <div>
        <Spinner size="small" />
      </div>
    );
  }

  return (
    <DataTable
      columnContentTypes={['text', 'text', 'text', 'text', 'text']}
      headings={['ID', 'Name', 'Webhook URL', 'Status', 'Action']}
      hasZebraStripingOnData
      rows={constructRows(
        setShowIntegrationModal,
        setChosenIntegrationData,
        integrationCallback.data,
        user.role_id
      )}
    />
  );
};

export default IntegrationInfo;
