import { useNavigate } from 'react-router-dom';

const ScenarioHeader = ({ scenario, sticky=true }) => {
  const navigateTo = useNavigate();
  return (
    <div className={`${sticky && 'sticky-tabs'} flex items-center text-[13px] p-2`}>
      <p
        className="font-semibold mr-1 underline underline-offset-4 cursor-pointer"
        onClick={() => navigateTo(-1)}>
        Scenario
      </p>
      <span> {`>`}</span>
      <p className="font-semibold ml-1">{scenario.title}</p>
    </div>
  );
};

export default ScenarioHeader;