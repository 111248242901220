/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  applyScenario,
  getAppliedScenarioForCamera,
  getScenarioByName
} from '../api/scenarios.api';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Spinner } from '@shopify/polaris';
import { getCameraById, getCamerasForWhichScenarioIsNotApplied } from '../api/cameras.api';
import { useDispatch, useSelector } from 'react-redux';
import {
  setChosenScenario,
  setNotificationGroups,
  setScenarioFormData
} from '../store/slices/scenarioSlice';
import Step1 from '../components/apply-scenario-form/Step-1';
import Step2 from '../components/apply-scenario-form/Step-2';
import Step3 from '../components/apply-scenario-form/Step-3';
import Step4 from '../components/apply-scenario-form/Step-4';
import { notification } from 'antd';
import { getNotificationGroups } from '../api/notification-groups.api';

export const PAGE_COUNT = 6;

const stepInfoText = {
  1: {
    title: 'Select Cameras',
    description: 'Select the cameras you want to apply this scenario to.',
    back: 'Cancel',
    next: 'Next'
  },
  2: {
    title: 'Events & Notifications',
    description: 'Select the events and notifications you want to apply this scenario to.',
    back: 'Back',
    next: 'Next'
  },
  3: {
    title: 'Prediction Settings',
    description: 'Select the prediction settings you want to apply this scenario to.',
    back: 'Back',
    next: 'Submit'
  }
};

const Context = React.createContext({ name: 'Default' });

const areZonesConfigured = (polygonPointsPerCamera, cameras) => {
  const cameraNames = cameras.map((camera) => camera.name);
  for (let i = 0; i < cameraNames.length; i++) {
    if (!(cameraNames[i] in polygonPointsPerCamera)) return false;
    if (
      polygonPointsPerCamera[cameraNames[i]].length === 0 ||
      polygonPointsPerCamera[cameraNames[i]][0].length === 0
    ) {
      return false;
    }
  }
  return true;
};

const ApplyScenarios = ({ edit = false }) => {
  const { id, name, cameraId } = useParams();
  const [scenarioToEdit, setScenarioToEdit] = useState({});

  const { state } = useLocation();
  const [step, setStep] = useState(1);
  const [stepInfo, setStepInfo] = useState({
    ...stepInfoText[1]
  });
  const [api, contextHolder] = notification.useNotification({
    stack: {
      threshold: 3
    }
  });

  const contextValue = useMemo(() => ({ name: 'Apply Scenario' }), []);

  const getNotificationGroupsFromIdString = (idString) => {
    if (idString === '' || idString !== 'null' || idString !== undefined || idString !== null) return [];
    if (!notificationGroupData) return [];
    const ids = idString.split(',').map((id) => Number(id));
    return notificationGroupData
      .filter((group) => {
        const id = group.value.id;
        if (ids.includes(id)) return true;
        return false;
      })
      .map((gr) => {
        return {
          name: gr.value.name,
          id: gr.value.id
        };
      });
  };

  const showErrorToast = ({ message, description, duration = 4 }) => {
    api.error({
      message,
      description,
      duration
    });
  };

  const { scenarioFormData: formData, chosenScenario } = useSelector((state) => state.scenario);
  const dispatch = useDispatch();

  const setFormData = (newState) => {
    dispatch(setScenarioFormData(newState));
  };

  const [options, setOptions] = useState([]);
  const [paginationData, setPaginationData] = useState({
    cameras: [],
    currentPage: 1,
    hasNext: false,
    hasPrevious: false
  });

  const navigateTo = useNavigate();
  const {
    data: scenarioData,
    isLoading: scenarioIsLoading,
    isSuccess
  } = useQuery({
    queryKey: [name],
    queryFn: () => getScenarioByName(name)
  });

  const {
    data: notificationGroupData,
    isLoading: isNotificationGroupLoading,
    isSuccess: isNotificationGroupSuccess
  } = useQuery({
    queryKey: ['notificationGroups'],
    queryFn: () => getNotificationGroups()
  });

  const { data: cameraData, isLoading: cameraLoading } = useQuery({
    queryKey: ['cameras', scenarioData?.title],
    queryFn: () => getCamerasForWhichScenarioIsNotApplied(scenarioData?.title),
    initialData: [],
    enabled: !edit
  });

  const { data: cameraDataForEdit, isLoading: cameraLoadingForEdit } = useQuery({
    queryKey: ['cameras', scenarioToEdit?.name, cameraId],
    queryFn: () => getCameraById(cameraId),
    initialData: [],
    enabled: edit
  });

  const {
    isPending: isApplying,
    isError,
    mutate
  } = useMutation({
    mutationFn: () => applyScenario(formData, scenarioData),
    onError: (error) => {
      const errorMessage = error.message || 'An unexpected error occurred.';
      api.error({ message: errorMessage });
    }
  });

  const memoizedScenarioData = useMemo(() => scenarioData, [scenarioData]);

  const handleFormBack = () => {
    if (step === 1) navigateTo(-1);
    if (step > 1) setStep(step - 1);
  };

  const handleFormNext = useCallback(() => {
    try {
      if (step === 1) {
        if (formData.cameras.length === 0) {
          return showErrorToast({
            message: 'No Cameras Selected',
            description: 'Please select atleast one camera to proceed'
          });
        }
        if (scenarioData?.zone_required) {
          if (!areZonesConfigured(formData.polygonPointsPerCamera, formData.cameras)) {
            return showErrorToast({
              message: 'Configure Zones',
              description: 'Please mark a zone on all selected cameras to proceed.'
            });
          }
        }
      }
      if (step === 2) {
        if (Object.values(formData.events).every((event) => !event)) {
          return showErrorToast({
            message: 'No Events Selected',
            description: 'Please select atleast one event to proceed'
          });
        }
        if (
          formData.startTime === '' ||
          formData.endTime === '' ||
          formData.startTime >= formData.endTime ||
          formData.startTime === formData.endTime ||
          !formData.startTime ||
          !formData.endTime
        ) {
          return showErrorToast({
            message: 'Invalid Time',
            description: 'Please select a valid time range to proceed'
          });
        }
        /* Code Commented to Not Throw error, since option to choose Notification Group(s) has been deprecated */
        // if (formData.selectedNotificationGroups.length === 0) {
        //   return showErrorToast({
        //     message: 'No Notification Group Selected',
        //     description: 'Please select atleast one notification group to proceed'
        //   });
        // }
      }
      if (step === 3) {
        if (
          formData.modelConfidence === '' ||
          formData.modelConfidence < 0 ||
          !formData.modelConfidence
        ) {
          return showErrorToast({
            message: 'Invalid Confidence',
            description: 'Please select a valid confidence to proceed'
          });
        }
        if (
          formData.detectionDuration === '' ||
          formData.detectionDuration < 0 ||
          !formData.detectionDuration
        ) {
          return showErrorToast({
            message: 'Invalid Duration',
            description: 'Please select a valid duration to proceed'
          });
        }
        if (formData.modelVersion === '' || !formData.modelVersion) {
          return showErrorToast({
            message: 'Invalid Model Version',
            description: 'Please select a valid model version to proceed'
          });
        }
        if (formData.hysteresisTime === '' || formData.hysteresisTime < 0) {
          return showErrorToast({
            message: 'Invalid Hysteresis Time',
            description: 'Please select a valid hysteresis time to proceed'
          });
        }

        if(chosenScenario.name === 'min-workers') {
          if (formData.minWorkerCount === '' || formData.minWorkerCount <= 0) {
            return showErrorToast({
              message: 'Invalid Minimum Worker Count',
              description: 'Please select a valid minimum worker count to proceed'
            });
          }
        }

        if (chosenScenario.name === 'max-workers') {
          if (formData.maxWorkerCount === '' || formData.maxWorkerCount <= 0) {
            return showErrorToast({
              message: 'Invalid Maximum Worker Count',
              description: 'Please select a valid maximum worker count to proceed'
            });
          }
        }
      }
      if (step === 3) {
        mutate();
        return setStep(step + 1);
      }
      if (step < 4) setStep(step + 1);
    } catch (e) {
      console.log({ e });
    }
  }, [formData, step]);

  useEffect(() => {
    if (edit) {
      setScenarioToEdit(state.scenario);
    }
  }, []);

  useEffect(() => {
    setStepInfo(stepInfoText[step]);
  }, [step]);

  useEffect(() => {
    if (!edit) {
      setPaginationData({
        cameras: cameraData?.slice(0, PAGE_COUNT),
        currentPage: 1,
        hasNext: cameraData?.length > PAGE_COUNT,
        hasPrevious: false
      });
    } else {
      setPaginationData({
        cameras: [cameraDataForEdit],
        currentPage: 1,
        hasNext: false,
        hasPrevious: false
      });
    }
  }, [cameraData, cameraDataForEdit]);

  useEffect(() => {
    if (isSuccess && scenarioData) {
      dispatch(setChosenScenario(scenarioData));
    }
  }, [dispatch, scenarioData, isSuccess]);

  useEffect(() => {
    if (isNotificationGroupSuccess && notificationGroupData) {
      dispatch(setNotificationGroups(notificationGroupData));
    }
    if (edit) {
      if (!isNotificationGroupLoading && isNotificationGroupSuccess) {
        setFormData({
          ...formData,
          selectedNotificationGroups: getNotificationGroupsFromIdString(
            state.scenario.notification_groups_users
          )
        });
      }
    }
  }, [dispatch, notificationGroupData, isNotificationGroupSuccess]);

  const getPolygonPointsPerCamera = (cameraName, scenarioMetadata) => {
    if (scenarioMetadata.bboxes?.length === 0) return { [cameraName]: [[]] };
    if (Object.keys(scenarioMetadata).length === 0) return { [cameraName]: [[]] };
    const zonesArr = scenarioMetadata?.bboxes[0]?.bbox[0]?.map((zone) => zone) ?? [];
    return { [cameraName]: zonesArr };
  };

  const getImageDimensionsPerCamera = (cameraName, scenarioMetadata) => {
    if (!('imageDimensions' in scenarioMetadata)) return { [cameraName]: { height: 0, width: 0 } };
    if (!(cameraName in scenarioMetadata.imageDimensions))
      return { [cameraName]: { height: 0, width: 0 } };
    return { [cameraName]: scenarioMetadata.imageDimensions[cameraName] };
  };

  useEffect(() => {
    const models = scenarioData?.models;
    setOptions([
      {
        label: `Version ${models?.latest?.version ?? '0.0.1'}`,
        value: models?.latest?.version ?? '0.0.1'
      }
    ]);

    const eventsObj = scenarioData?.events;
    let events = {};
    if (eventsObj && edit) {
      events = Object.keys(eventsObj).reduce((acc, event) => {
        if (state.scenario.selected_events.split(',').includes(event)) acc[event] = true;
        else acc[event] = false;
        return acc;
      }, events);
    }

    if (edit) {
      let scenarioMetadata;

      // Check if scenario_metadata is a string. If so, attempt to parse it.
      if (typeof state.scenario.scenario_metadata === 'string') {
        try {
          scenarioMetadata = JSON.parse(state.scenario.scenario_metadata);
        } catch (error) {
          console.error('Error parsing scenario_metadata:', error);
          // Handle the error (e.g., set scenarioMetadata to a default value or re-throw the error)
          scenarioMetadata = {}; // setting to an empty object as a fallback
        }
      } else if (
        typeof state.scenario.scenario_metadata === 'object' &&
        state.scenario.scenario_metadata !== null
      ) {
        // If it's an object and not null, use it directly.
        scenarioMetadata = state.scenario.scenario_metadata;
      } else {
        // If it's neither a string nor an object, handle this case (e.g., log an error, set a default value).
        scenarioMetadata = {}; // setting to an empty object as a fallback
      }
      const polygonPointsPerCamera = getPolygonPointsPerCamera(
        state.scenario.camera_name,
        scenarioMetadata
      );
      const imageDimensionsPerCamera = getImageDimensionsPerCamera(
        state.scenario.camera_name,
        scenarioMetadata
      );
      setFormData({
        ...formData,
        events: events,
        modelConfidence: state.scenario.selected_confidence,
        selectedNotificationGroups: getNotificationGroupsFromIdString(
          state.scenario.notification_groups_users
        ),
        polygonPointsPerCamera,
        maxWorkerCount: scenarioMetadata.maxWorkerCount ?? 1,
        minWorkerCount: scenarioMetadata.minWorkerCount ?? 1,
        eventLevel: scenarioMetadata.event_level ? [scenarioMetadata.event_level] : ['ALL'],
        endTime: scenarioMetadata.endTime ?? '23:59',
        startTime: scenarioMetadata.startTime ?? '00:00',
        detectionDuration: scenarioMetadata.detectionDuration ?? 12,
        hysteresisTime: scenarioMetadata.hysteresisTime ?? 20,
        imageDimensionsPerCamera,
        actualImageDimensionsPerCamera: imageDimensionsPerCamera,
        cameraName: state.scenario.camera_name
      });
    }

    if (!edit) {
      setFormData({ ...formData, modelVersion: models?.latest?.version ?? '0.0.1' });
    }
  }, [scenarioData]);

  if (scenarioIsLoading || isNotificationGroupLoading || cameraLoading || cameraLoadingForEdit)
    return (
      <div className="h-screen grid place-items-center">
        <Spinner accessibilityLabel="Scenario Loading" size="large" color="teal" />
      </div>
    );
  return (
    <Context.Provider value={contextValue}>
      {contextHolder}
      <div>
        <div className="sticky-tabs flex items-center text-[13px] p-2">
          <p
            className="font-semibold mr-1 underline underline-offset-4 cursor-pointer"
            onClick={() => {
              if (edit) navigateTo(-1, { state: { camera: state.camera } });
              else {
                navigateTo(-1);
              }
            }}>
            {edit ? `${state.camera.name}` : 'Scenarios'}
          </p>
          <span> {`>`}</span>
          <p className="font-semibold ml-1">{scenarioData.title}</p>
        </div>
        <div className="grid mx-4 place-items-center h-[90vh]">
          <div className="bg-white w-full xl:w-2/3 h-auto p-4 rounded-md">
            {step !== 4 && (
              <div>
                <div className="step-header flex items-center">
                  <span className="inline-block px-3 py-1 rounded-full bg-[#EBEBEB] text-black mr-6">
                    {step}
                  </span>
                  <div className="flex items-center flex-wrap gap-5 justify-between w-full">
                    <div>
                      <h6 className="font-semibold">{stepInfo.title}</h6>
                      <p className="text-[13px]">{stepInfo.description}</p>
                    </div>
                    <div className='flex'>
                      <span className="mr-2">
                        <Button onClick={handleFormBack} variant="secondary">
                          {stepInfo.back}
                        </Button>
                      </span>
                      <span>
                        <Button onClick={handleFormNext} variant="primary">
                          {stepInfo.next}
                        </Button>
                      </span>
                    </div>
                  </div>
                </div>

                {step === 1 && (
                  <Step1
                    edit={edit}
                    cameraId={cameraId}
                    formData={formData}
                    setFormData={setFormData}
                    cameraLoading={cameraLoading}
                    paginationData={paginationData}
                    setPaginationData={setPaginationData}
                    cameraData={cameraData}
                  />
                )}
                {step === 2 && (
                  <Step2
                    formData={formData}
                    setFormData={setFormData}
                    scenarioData={memoizedScenarioData}
                  />
                )}
                {step === 3 && (
                  <Step3 formData={formData} setFormData={setFormData} options={options} />
                )}
              </div>
            )}
            {step === 4 && (
              <Step4
                formData={formData}
                isApplying={isApplying}
                isError={isError}
                scenarioData={memoizedScenarioData}
              />
            )}
          </div>
        </div>
      </div>
    </Context.Provider>
  );
};

export default ApplyScenarios;
