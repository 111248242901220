import { Badge, Button, DataTable, Icon, Spinner } from '@shopify/polaris';
import { EditMajor } from '@shopify/polaris-icons';
import { useSelector } from 'react-redux';

const constructRows = (setShowEditModal, setChosenSiteData, data, roleId) => {
    const rows = [];
    data.map((site) => {
      rows.push([
        site.id, 
        site.name, 
        site.cameraCount, 
        <Badge key={site.id} tone={`${site.status? 'success':'critical'}`}>{site.status ? 'Active': 'Inactive'}</Badge>,  
        <Button key={`button-${site.id}`} disabled={!(roleId === 1 || roleId === 2)}
        onClick={() => {
          setShowEditModal(true)
          setChosenSiteData(site)
        }}>
              <Icon source={EditMajor} />
           </Button>])
    })
    return rows;

}
const SiteInfo = ({setShowSiteEditModal, siteCallback, setChosenSiteData}) => {  
  const {user} = useSelector(state => state.auth)
  if (siteCallback.isLoading) {
    return <div>
      <Spinner size='small' />
    </div>
  }
  return (
    <>
    <DataTable
      columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text']}
      headings={['ID', 'Name', 'Camera Count', 'Status', 'Action']}
      hasZebraStripingOnData
      rows={constructRows(setShowSiteEditModal,setChosenSiteData, siteCallback.data, user.role_id)}
    />
    </>
  );
};

export default SiteInfo;