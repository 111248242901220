import { Button, Spinner, TextField } from '@shopify/polaris';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { setPassword as setPasswordApi, verifyJwt } from '../api/users.api';

const Header = () => {
  const rawSVG = `
    <?xml version="1.0" encoding="utf-8"?>
    <svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg" xmlns:bx="https://boxy-svg.com">
      <circle style="stroke: rgb(0, 0, 0);" transform="matrix(2.827216, 0.248675, -0.245068, 2.893302, -429.662323, -428.847748)" cx="257.6253712829648" cy="214.12353544593623" r="52.480771186458036"/>
      <circle style="stroke: rgb(255, 255, 255); fill-rule: nonzero; fill: rgb(255, 255, 255); paint-order: fill;" transform="matrix(-1.619828, 1.468131, -1.459024, -1.671637, 1134.883179, 42.811668)" cx="299.2081085582722" cy="197.5730116796036" r="34.45563056685645"/>
      <path d="M 248.687 250.058 m -242.726 0 a 242.726 243.138 0 1 0 485.452 0 a 242.726 243.138 0 1 0 -485.452 0 Z M 248.687 250.058 m -145.635 0 a 145.635 145.881 0 0 1 291.27 0 a 145.635 145.881 0 0 1 -291.27 0 Z" style="stroke: rgb(255, 255, 255); fill: rgb(255, 255, 255);"/>
    </svg>
  `;

  return (
    <div>
      <div className="flex flex-col-reverse md:flex-row justify-between md:items-center bg-white">
        <h1 className="text-xl font-bold">Welcome to Visionify</h1>
        <div className="w-12 h-12" dangerouslySetInnerHTML={{ __html: rawSVG }} />
      </div>
    </div>
  );
};

const redirectionUrl = new URL(window.location.href).origin;

const SetPassword = () => {
  const [searchParams] = useSearchParams();

  const [token] = useState(searchParams.get('token') ?? null);
  const [flow] = useState(searchParams.get('flow') ?? null);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const [hasPasswordFlowEnded, setHasPasswordFlowEnded] = useState(false);
  const [showInvalidPage, setShowInvalidPage] = useState(false);

  const [validations, setValidations] = useState({
    characterLength: null,
    specialCharacter: null,
    uppercaseCharacter: null,
    passwordsMatch: false
  });

  const onPasswordChange = (newValue) => {
    setError('');
    setPassword(newValue);
    setValidations({
      characterLength: newValue.length >= 8,
      specialCharacter: /[!@#$%^&*(),.?":{}|<>]/.test(newValue),
      uppercaseCharacter: /[A-Z]/.test(newValue),
      passwordsMatch: newValue === confirmPassword
    });
  };

  const onConfirmPasswordChange = (newValue) => {
    setError('');
    setConfirmPassword(newValue);
    setValidations({
      ...validations,
      passwordsMatch: newValue === password
    });
  };

  const { data, isError, isLoading } = useQuery({
    queryKey: ['verifyJwt', token],
    queryFn: () => verifyJwt(token)
  });

  useEffect(() => {
    if (isError) {
      setShowInvalidPage(true);
    }
  }, [isError]);

  const { mutate } = useMutation({
    mutationFn: () => setPasswordApi({ jwt: token, password }),
    onSuccess: () => {
      setHasPasswordFlowEnded(true);
    },
    onError: () => {
      setError('Unable to set password! Please try again later.');
    }
  });

  const setPasswordFlow = (e) => {
    e.preventDefault();
    if (!validations.passwordsMatch) {
      setError('Passwords do not match');
      return;
    }

    // check if all validations are true
    const failedValidations = Object.keys(validations).filter((validationKey) => {
      if (validations[validationKey] === false) {
        return true;
      } else {
        return false;
      }
    });

    if(failedValidations.length > 0) {
      return setError('Password does not meet the requirements');
    }

    mutate();
  };

  if (isLoading) {
    return (
      <div className="h-screen grid place-items-center">
        <Spinner size="large" color="teal" accessibilityLabel="Loading" />
      </div>
    );
  }

  if (!token || !flow || showInvalidPage) {
    return (
      <div className="h-screen grid place-items-center">
        <div className="p-4 w-1/2 lg:w-1/4 border rounded-lg shadow-xl bg-white">
          <Header />
          <h1 className="text-center text-lg">Your invite has expired or is invalid. Ask your admin to send you an invite!</h1>
        </div>
      </div>
    );
  }
  return (
    <div className="grid place-items-center h-screen">
      <div className="p-4 w-5/6 lg:w-1/3 border rounded-lg shadow-xl bg-white">
        <Header flow={flow} />
        {hasPasswordFlowEnded ? (
          <div>
            <h1>Password has been set successfully</h1>
            <div className="w-full text-center mt-4">
              {/* <Link
                to={redirectionUrl}
                className="text-lg font-medium underline hover:text-gray-600">
                Proceed to login {'>'}
              </Link> */}
              <Button variant="primary" onClick={() => (window.location.href = redirectionUrl)}>
                Proceed to Login {'>'}
              </Button>
            </div>
          </div>
        ) : (
          <form onSubmit={setPasswordFlow}>
            <TextField label="Email" type="text" disabled value={data?.user?.email} />
            <TextField
              label="Password"
              type="password"
              value={password}
              onChange={onPasswordChange}
            />
            <TextField
              label="Confirm Password"
              type="password"
              value={confirmPassword}
              onChange={onConfirmPasswordChange}
            />
            <div id="validation-information" className="mt-2">
              <p
                className={`${validations.characterLength !== null ? (validations.characterLength ? 'text-green-500' : 'text-red-500') : 'text-gray-400'} text-xs`}>
                * Password must be at least 8 characters
              </p>
              <p
                className={`${validations.specialCharacter !== null ? (validations.specialCharacter ? 'text-green-500' : 'text-red-500') : 'text-gray-400'} text-xs`}>
                * Password must contain atleast one special character
              </p>
              <p
                className={`${validations.uppercaseCharacter !== null ? (validations.uppercaseCharacter ? 'text-green-500' : 'text-red-500') : 'text-gray-400'} text-xs`}>
                * Password must contain atleast one uppercase character
              </p>
            </div>
            {error && <p className="text-red-500 text-xs">* {error}</p>}

            <div className="mt-4">
              <Button disabled={password === '' || confirmPassword === ''} submit variant="primary">
                Set Password
              </Button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default SetPassword;
