import { Badge, Button, Card, DataTable, Select } from '@shopify/polaris';
import { EditMajor, PlusMinor, DeleteMajor } from '@shopify/polaris-icons';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteSpeaker, getSpeakers } from '../../api/speakers.api';
import { notification } from 'antd';
import { useMutation } from '@tanstack/react-query';

const Speakers = () => {
  const [speakers, setSpeakers] = useState([]);
  const [selectedSpeaker, setSelectedSpeaker] = useState('All');
  const [selectedStatus, setSelectedStatus] = useState('All');
  const [totalSpeakers, setTotalSpeakers] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();

  const fetchSpeakers = async (page) => {
    try {
      const response = await getSpeakers(page, pageSize);
      setSpeakers(response.speakers);
      setTotalSpeakers(response.total);
    } catch (error) {
      console.error('Error fetching speakers:', error);
    }
  };

  useEffect(() => {
    fetchSpeakers(currentPage);
  }, [currentPage]);

  const handleSpeakerChange = useCallback((value) => setSelectedSpeaker(value), []);
  const handleStatusChange = useCallback((value) => setSelectedStatus(value), []);
  const handlePageChange = (page) => setCurrentPage(page);

  const deleteMutation = useMutation({
    mutationFn: deleteSpeaker,
    onSuccess: () => {
      api.success({
        message: 'Speaker deleted successfully',
        placement: 'topRight'
      });
      fetchSpeakers(currentPage);
    },
    onError: (error) => {
      api.error({
        message: 'Error deleting speaker',
        description: error.message,
        placement: 'topRight'
      });
    }
  });

  const handleDelete = (id) => {
    deleteMutation.mutate(id);
  };

  const speakerOptions = [
    { label: 'All', value: 'All' },
    ...speakers.map((speaker) => ({ label: speaker.speaker_name, value: speaker.speaker_name }))
  ];

  const statusOptions = [
    { label: 'All', value: 'All' },
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Inactive', value: 'INACTIVE' }
  ];

  const filteredRows = speakers
    .filter((speaker) => selectedSpeaker === 'All' || speaker.speaker_name === selectedSpeaker)
    .filter((speaker) => selectedStatus === 'All' || speaker.status === selectedStatus)
    .map((speaker) => [
      speaker.speaker_name,
      speaker.ip_address,
      <Badge tone={speaker.status === 'ACTIVE' ? 'success' : 'critical'} key="status">
        {speaker.status}
      </Badge>,
      <div key="action" className="flex items-center gap-4">
        <Button onClick={() => navigate(`/speakers/${speaker.id}`)} icon={EditMajor}></Button>
        <Button onClick={() => handleDelete(speaker.id)} icon={DeleteMajor}></Button>
      </div>
    ]);

  return (
    <>
      {contextHolder}
      <div className="radiousnone main-title">
        <div className="bg-white py-3 px-3 shadow-md border-b">
          <div className="title smallbtn">
            <div className="filter grid grid-cols-2 md:gri-cols-3 lg:grid-cols-3 gap-4">
              <div>
                <Select
                  label="Speaker"
                  options={speakerOptions}
                  onChange={handleSpeakerChange}
                  value={selectedSpeaker}
                />
              </div>
              <div>
                <Select
                  label="Status"
                  options={statusOptions}
                  onChange={handleStatusChange}
                  value={selectedStatus}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="cus_table_design px-4 padding0 mt-4">
        <Card>
          <div className="flex items-center justify-between">
            <h3 className="font-semibold text-[18px]">Speakers</h3>
            <Button
              onClick={() => navigate(`/speakers/create`)}
              icon={PlusMinor}
              key="Inferencevideo">
              Add
            </Button>
          </div>
          <DataTable
            verticalAlign="middle"
            columnContentTypes={['text', 'text', 'text', 'text']}
            headings={['Name', 'IP Address', 'Status', 'Action']}
            rows={filteredRows}
            hasZebraStripingOnData
            pagination={{
              hasNext: currentPage < Math.ceil(totalSpeakers / pageSize),
              hasPrevious: currentPage > 1,
              onNext: () => handlePageChange(currentPage + 1),
              onPrevious: () => handlePageChange(currentPage - 1),
              accessibilityLabel: 'Pagination Navigation',
              label: `Page ${currentPage} of ${Math.ceil(totalSpeakers / pageSize)}`,
              type: 'table'
            }}
          />
        </Card>
      </div>
    </>
  );
};

export default Speakers;
