import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    appearance: {
        gradient: `from-[#E3F0DF] to-[#CBE9C0]`
    },
    roles: []
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAvatarGradient: (state, action) => {
        state.appearance.gradient = action.payload
    },
    setRoles: (state, action) => {
      state.roles = action.payload
    }
  }
});

export const {
    setAvatarGradient,
    setRoles
} = appSlice.actions;

export default appSlice.reducer;

