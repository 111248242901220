const ContentCardWithHeading = ({ children, title }) => {
    return (
      <div className="w-full">
        <div className="px-4 py-2 border rounded-t-xl">
          <h3 className="text-md font-semibold">{title}</h3>
        </div>
        <div className="px-4 py-2 bg-white rounded-b-xl">{children}</div>
      </div>
    );
  };

export default ContentCardWithHeading;