import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Icons } from '../../Icons';
import { useMutation } from '@tanstack/react-query';
import { updateTask } from '../../api/campaigns.api';

const StatusDropdown = ({ currentStatus, onChange, id, refetch }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const { mutate: updateStatus } = useMutation({
    mutationFn: updateTask,
    onSettled: () => refetch()
  });

  const statusOptions = [
    { label: 'TODO', value: 'TODO', textcolor: 'gray-600', color: 'gray-200' },
    { label: 'DOING', value: 'DOING', textcolor: 'yellow-600', color: 'yellow-200' },
    { label: 'DONE', value: 'DONE', textcolor: 'green-600', color: 'green-200' }
  ];

  const getStatusStyles = (status) => {
    const option = statusOptions.find((option) => option.value === status);
    return {
      backgroundColor: option ? option.color : '',
      textColor: option ? option.textcolor : ''
    };
  };

  const { backgroundColor, textColor } = getStatusStyles(currentStatus);

  const handleOptionClick = (status) => {
    onChange(status.value);
    setIsOpen(false);
    updateStatus({ id, payload: { status: status.value } });
  };

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen]);

  const renderDropdown = () => {
    const dropdown = (
      <ul
        className="absolute z-50 w-[150px] bg-white shadow-md rounded-md"
        ref={dropdownRef}
        style={{
          zIndex: 9999,
          position: 'absolute',
          top: buttonRef.current?.getBoundingClientRect().bottom + window.scrollY,
          left: buttonRef.current?.getBoundingClientRect().left + window.scrollX,
        }}
      >
        {statusOptions.map((status) => (
          <li
            key={status.value}
            className={`cursor-pointer p-2 ${status.color}`}
            onClick={() => handleOptionClick(status)}
          >
            {status.label}
          </li>
        ))}
      </ul>
    );

    return ReactDOM.createPortal(dropdown, document.body);
  };

  return (
    <div className="relative inline-block" ref={buttonRef}>
      <button
        className={`badge bg-${backgroundColor} text-${textColor} px-2 py-1 rounded-md flex gap-1`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {currentStatus}
        <Icons.DropdownArrow className="w-5 h-5" fill="#8A8A8A" />
      </button>
      {isOpen && renderDropdown()}
    </div>
  );
};

export default StatusDropdown;
