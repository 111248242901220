import { useState, useEffect } from 'react';

/**
 * Custom hook to generate a thumbnail(s) and get duration(s) from a video URL or an array of video URLs.
 * 
 * @param {string | string[]} videoUrls - A single video URL or an array of video URLs.
 * @param {number} seekTime - The time in seconds to seek into the video for the thumbnail.
 * @returns {Object | Object[]} An object or an array of objects, each containing a base64 encoded image URL and the video duration.
 */
const useVideoThumbnailWDuration = (videoUrls, seekTime = 1) => {
  const [thumbnailsAndDurations, setThumbnailsAndDurations] = useState(Array.isArray(videoUrls) ? [] : { thumbnailUrl: '', duration: 0 });

  useEffect(() => {
    if (!videoUrls) return;

    const urls = Array.isArray(videoUrls) ? videoUrls : [videoUrls];
    const results = [];

    urls.forEach((url) => {
      const video = document.createElement('video');
      video.src = url;
      video.crossOrigin = 'anonymous'; // Needed if the video is hosted on a different domain.
      video.addEventListener('loadedmetadata', () => {
        video.currentTime = seekTime;
        const duration = video.duration;
        video.addEventListener('seeked', () => {
          const canvas = document.createElement('canvas');
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          const imageData = canvas.toDataURL('image/jpeg'); // You can choose other formats like 'image/png'.
          
          const result = { thumbnailUrl: imageData, duration };
          results.push(result);

          // Update state appropriately based on input type
          if (Array.isArray(videoUrls)) {
            if (results.length === urls.length) {
              setThumbnailsAndDurations(results);
            }
          } else {
            setThumbnailsAndDurations(results[0]);
          }
        });
      });
    });
  }, [videoUrls, seekTime]);

  return thumbnailsAndDurations;
};

export default useVideoThumbnailWDuration;
