import { Spinner } from '@shopify/polaris';
import { Icons } from '../../Icons';
import ContentCardWithHeading from '../../components/ContentCardWithHeading';
import { useQuery } from '@tanstack/react-query';
import { getMyCompany } from '../../api/company.api';

const CompanyInfo = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['companyInfo'],
    queryFn: getMyCompany
  });


  if (isLoading)
    return (
      <div>
        <Spinner size="small" />
      </div>
    );
  return (
    <>
      <ContentCardWithHeading title={'Profile'}>
        <div className="flex my-2 items-center">
          <div>
            <Icons.company className="h-[151px] w-[151px]" />
          </div>
          <div className="px-4">
            <h3 className="text-lg font-semibold mb-2">{data.name}</h3>
            {data.address && (
              <div className="flex ">
                <Icons.locationPin className="hide md:block w-5 h-5 mr-1" />
                <span>{data.address}</span>
              </div>
            )}
            <div className="flex flex-col md:flex-row items-center mt-2">
              <div className="flex">
                <Icons.email className="w-5 h-5 mr-1" />
                <span>{data.email}</span>
              </div>
              <svg
                className="mx-2 hide md:block"
                width="5"
                height="5"
                viewBox="0 0 5 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <circle cx="2.5" cy="2.5" r="2.5" fill="#8A8A8A" />
              </svg>
              <div className="flex">
                <Icons.phone className="w-5 h-5 mr-1" />
                <span>{data.phone}</span>
              </div>
            </div>
          </div>
        </div>
      </ContentCardWithHeading>
    </>
  );
};

export default CompanyInfo;
