import { Badge, Button, Spinner } from '@shopify/polaris';
import { Modal } from 'antd';
import { Avatar } from './Avatar';

const getUserBadge = (roleId, roleName) => {
  switch (roleId) {
    case 1:
      return <Badge tone="positive">{roleName}</Badge>;
    case 2:
      return <Badge tone="magic">{roleName}</Badge>;
    case 3:
      return <Badge tone="enabled">{roleName}</Badge>;
    case 4:
      return <Badge tone="info">{roleName}</Badge>;
    case 5:
      return <Badge tone="attention">{roleName}</Badge>;
    case 6:
      return <Badge tone="info">{roleName}</Badge>;
    default:
      return <Badge tone="critical">Unknown</Badge>;
  }
}

const UsersListModal = ({ data, open, handleCancel, isLoading, isError }) => {
  return (
    <Modal
      title="Users List"
      open={open}
      onCancel={handleCancel}
      maskClosable={true}
      footer={[
        <div className="inline mr-2" key="back">
          <Button variant="primary" onClick={handleCancel}>
            Close
          </Button>
        </div>
      ]}>
      <form>
        <div className="w-full">
          <div className="flex items-center mb-2">
            {isLoading ? (
              <div className="flex justify-center w-full">
                <Spinner size="large" accessibilityLabel="Loading Users" />
              </div>
            ) : isError ? (
              <div>
                Something went wrong. Please try again later or contact{' '}
                <a
                  className="text-blue-500 hover:text-blue-900 underline underline-offset-2"
                  href="mailto:support@visionify.ai">
                  support@visionify.ai
                </a>
              </div>
            ) : (
              <div className="w-full">
                {data?.length ? data.map((userGroup) => (
                  <div
                    key={userGroup.id}
                    className="flex border rounded-md w-full my-2 py-2 px-4 shadow-lg">
                    <span className="mr-4">
                      <Avatar className='size-12' name={userGroup.user.name} textSize='text-xl'/>
                    </span>
                    <div className="flex flex-col">
                      <div className='flex'>
                        <span className="text-md font-semibold mr-1">{userGroup.user.name}</span>
                        {getUserBadge(userGroup.user.roleInfo.id, userGroup.user.roleInfo.name.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' '))}
                      </div>
                      <span className="text-md font-semibold">{userGroup.user.email}</span>
                    </div>
                  </div>
                )): <>
                No Users Found
                </>}
              </div>
            )}
          </div>
          {}
        </div>
      </form>
    </Modal>
  );
};
export default UsersListModal;
