import { useCallback } from 'react';
import { AutoSelection, Combobox, Icon, LegacyStack, Listbox, Tag } from '@shopify/polaris';
import { DropdownMinor } from '@shopify/polaris-icons';
import Spacer from './Spacer';

const ComboBoxWithMultipleOptions = ({
  optionsList = [],
  label = 'Select Events',
  placeholder = 'Select Events',
  selectedOptions,
  setSelectedOptions,
  noOptionsConfig = {
    element: <p className="px-4 py-2">No options available</p>,
    command: null,
    fn: null
  },
  addAction = () => {},
  removeAction = () => {} 
}) => {
  const removeTag = useCallback(
    (tag) => () => {
      const newOptions = [...selectedOptions];
      removeAction(tag)
      newOptions.splice(newOptions.indexOf(tag), 1);
      setSelectedOptions(newOptions);
    },
    [selectedOptions]
  );

  return (
    <div>
      <Combobox
        allowMultiple
        activator={
          <Combobox.TextField
            suffix={<Icon source={DropdownMinor} />}
            label={label}
            placeholder={placeholder}
            autoComplete="off"
          />
        }>
        <Listbox
          autoSelection={AutoSelection.None}
          onSelect={(selected) => {
            if (noOptionsConfig.command && selected === noOptionsConfig.command)
              return noOptionsConfig.fn();
            if (selectedOptions.some(opt => opt.name === selected.name)) {
              setSelectedOptions(selectedOptions.filter((option) => option.name !== selected.name));
              removeAction(selected)
            } else {
              setSelectedOptions([...selectedOptions, selected]);
              addAction(selected)
            }
          }}>
          {optionsList && optionsList.length !== 0
            ? optionsList.map((option, idx) => {
              const isSelected = selectedOptions.some(opt => opt.name === option.label);
                return (
                  <Listbox.Option
                    key={idx}
                    value={option.value}
                    selected={isSelected}
                    accessibilityLabel={option.label}>
                    {option.label}
                  </Listbox.Option>
                );
              })
            : null}
          {noOptionsConfig.element}
        </Listbox>
      </Combobox>
      <Spacer height={'3px'} />
      <div className="overflow-x-scroll w-full">
        {/* For hiding scrollbar in Firefox */}
        <div style={{ overflow: '-moz-scrollbars-none' }}>
          <LegacyStack wrap={false}>
            {selectedOptions &&
              selectedOptions.map((option) => (
                <Tag key={`option-${option}`} onRemove={removeTag(option)}>
                  {option.name}
                </Tag>
              ))}
          </LegacyStack>
        </div>
      </div>
      <style>
        {/* For hiding scrollbar in webkit based browsers like Edge, Safari, Chrome */}
        {`
      .overflow-x-scroll::-webkit-scrollbar {
        display: none;
      }
    `}
      </style>
    </div>
  );
};

export default ComboBoxWithMultipleOptions;
