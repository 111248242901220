import React, { useRef, useState } from 'react';
import { TextField, InlineError, Select, Spinner, Modal, Icon } from '@shopify/polaris';
import { useMutation } from '@tanstack/react-query';
import { uploadAttachmentToAzure } from '../../api/views.api';
import { CircleCancelMajor } from '@shopify/polaris-icons';

const FileUploadModal = ({ cameraViewId, onUploadSuccess, isOpen, onClose }) => {
  const [files, setFiles] = useState([]);
  const [attachmentNames, setAttachmentNames] = useState({});
  const [attachmentTypes, setAttachmentTypes] = useState({});
  const [durations, setDurations] = useState({});
  const [error, setError] = useState({});
  const [fileError, setFileError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const fileInputRef = useRef(null);

  const attachmentTypeOptions = [
    { label: 'Select attachment type', value: '' },
    { label: 'Image', value: 'image' },
    { label: 'Video', value: 'video' }
    // { label: 'File', value: 'file' }
  ];

  const { mutate } = useMutation({
    mutationFn: (data) => uploadAttachmentToAzure(data.cameraViewId, data.formData),
    onSuccess: () => {
      if (onUploadSuccess) onUploadSuccess();
      resetState(); // Reset files and inputs on success
      onClose();
    },
    onError: () => {
      setError({ submit: 'Error uploading attachments' });
      setIsSubmitting(false);
    }
  });

  // Handle file selection
  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    const newFileNames = newFiles.map((file) => file.name);
    const allowedTypes = [
      'image/jpeg',
      'image/png',
      'image/gif',
      'video/mp4',
      'video/avi',
      'video/mov'
    ];

    const invalidFiles = newFiles.filter((file) => !allowedTypes.includes(file.type));
    if (invalidFiles.length > 0) {
      setFileError('Only image and video files are allowed.');
      return;
    }

    const hasDuplicate = files.some((file) => newFileNames.includes(file.name));
    if (hasDuplicate) {
      setError({ files: 'Duplicate files are not allowed' });
      return;
    }

    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setFileError('');
    fileInputRef.current.value = null;
  };

  // Handle removing a file
  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, fileIndex) => fileIndex !== index));
    setAttachmentNames((prev) => {
      const newAttachmentNames = { ...prev };
      delete newAttachmentNames[index];
      return newAttachmentNames;
    });
    setAttachmentTypes((prev) => {
      const newAttachmentTypes = { ...prev };
      delete newAttachmentTypes[index];
      return newAttachmentTypes;
    });
    setDurations((prev) => {
      const newDurations = { ...prev };
      delete newDurations[index];
      return newDurations;
    });

    // Reset the file input
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  // Reset all state when modal is closed
  const resetState = () => {
    setFiles([]);
    setAttachmentNames({});
    setAttachmentTypes({});
    setDurations({});
    setError({});
    setFileError('');
    setIsSubmitting(false);
  };

  // Handle input changes for attachment name, type, and duration
  const handleInputChange = (index, field, value) => {
    if (field === 'attachmentName') {
      setAttachmentNames((prev) => ({ ...prev, [index]: value }));
    } else if (field === 'attachmentType') {
      setAttachmentTypes((prev) => ({ ...prev, [index]: value }));
      if (value === 'video') {
        // Set duration to null if attachment type is video
        setDurations((prev) => ({ ...prev, [index]: null }));
      } else {
        // Set default duration to 5 seconds for non-video files
        setDurations((prev) => ({ ...prev, [index]: 5 }));
      }
    } else if (field === 'duration') {
      setDurations((prev) => ({ ...prev, [index]: value }));
    }
    setError((prev) => ({ ...prev, [field]: null }));
  };

  // Validate file type based on attachment type selection
  const validateFileType = (index, file) => {
    const fileType = file.type.startsWith('image') ? 'image' : 'video';
    const selectedAttachmentType = attachmentTypes[index];

    // Only validate if both file type and attachment type are set
    if (selectedAttachmentType && fileType !== selectedAttachmentType) {
      setFileError(
        `File type does not match the selected attachment type. Expected ${selectedAttachmentType}.`
      );
      return false;
    }
    return true;
  };

  // Handle file upload
  const handleUpload = () => {
    if (files.length === 0) {
      setFileError('At least one file is required');
      return;
    }

    let hasErrors = false;

    // Check attachment names, types, and durations
    files.forEach((file, index) => {
      if (!attachmentNames[index]) {
        setError((prev) => ({
          ...prev,
          attachmentNames: 'Attachment name is required for all files'
        }));
        hasErrors = true;
      }
      if (!attachmentTypes[index]) {
        setError((prev) => ({
          ...prev,
          attachmentTypes: 'Attachment type is required for all files'
        }));
        hasErrors = true;
      }
      const isVideo = attachmentTypes[index] === 'video';
      if (!isVideo && (!durations[index] || durations[index] < 2)) {
        setError((prev) => ({
          ...prev,
          durations: 'Duration must be at least 2 seconds for non-video files'
        }));
        hasErrors = true;
      }

      // Validate file type
      if (!validateFileType(index, file)) {
        hasErrors = true;
      }
    });

    if (hasErrors) {
      return;
    }

    setIsSubmitting(true);
    const formData = new FormData();

    // Append files
    files.forEach((file) => {
      formData.append('files', file);
    });

    // Append metadata arrays (attachmentNames, attachmentTypes, durations)
    Object.keys(attachmentNames).forEach((index) => {
      formData.append('attachmentNames[]', attachmentNames[index]);
      formData.append('attachmentTypes[]', attachmentTypes[index]);

      // Set duration to null for videos, otherwise keep the provided duration
      const duration = attachmentTypes[index] === 'video' ? null : durations[index];
      formData.append('durations[]', duration);
    });

    formData.append('cameraViewId', cameraViewId);
    mutate({ cameraViewId, formData });
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        resetState();
        onClose();
      }}
      title="Upload Attachments"
      large
      primaryAction={{
        content: isSubmitting ? <Spinner size="small" /> : 'Upload',
        onAction: handleUpload,
        disabled: isSubmitting
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: () => {
            resetState();
            onClose();
          }
        }
      ]}>
      <Modal.Section>
        <div className="basicdetail height-43 space-y-5 pt-5 pb-5">
          {files.map((file, index) => (
            <div key={index} className="grid grid-cols-1 md:grid-cols-4 gap-5 w-full items-center">
              <TextField
                label="Attachment Name"
                value={attachmentNames[index] || ''}
                onChange={(value) => handleInputChange(index, 'attachmentName', value)}
                error={error.attachmentNames && 'Attachment name is required'}
              />
              <Select
                label="Attachment Type"
                options={attachmentTypeOptions}
                value={attachmentTypes[index] || ''}
                onChange={(value) => handleInputChange(index, 'attachmentType', value)}
                error={error.attachmentTypes && 'Attachment type is required'}
              />
              {attachmentTypes[index] !== 'video' && (
                <TextField
                  label="Duration (seconds)"
                  type="number"
                  value={durations[index] || 5} // Default value is 5
                  onChange={(value) => handleInputChange(index, 'duration', value)}
                  error={
                    durations[index] &&
                    durations[index] < 2 &&
                    'Duration must be at least 2 seconds'
                  }
                />
              )}
              <div className="flex items-center gap-2">
                {file.name}
                <div className="cursor-pointer" onClick={() => handleRemoveFile(index)}>
                  <Icon source={CircleCancelMajor} color="critical" />
                </div>
              </div>
            </div>
          ))}
          <div>
            <input
              type="file"
              multiple
              onChange={handleFileChange}
              ref={fileInputRef}
              id="file-upload"
              className="hidden"
            />
            <label
              htmlFor="file-upload"
              className="cursor-pointer inline-block px-4 py-2 bg-blue-500 text-white rounded">
              {files.length > 0 ? 'Add More Files' : 'Upload Files'}
            </label>
            {fileError && <InlineError message={fileError} />}
          </div>
        </div>
      </Modal.Section>
    </Modal>
  );
};

export default FileUploadModal;
