/* eslint-disable no-unused-vars */
import { Button, Spinner } from '@shopify/polaris';
import { Modal } from 'antd';
const DeleteConfirmationModal = ({ show, handleClose, handleDelete, prompt, isDeletionPending, isDeleteError, errorMessage}) => {
  return (
    <Modal
      open={show}
      closeIcon={null}
      footer={[
        <span className="mr-2" key="back">
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </span>,
        <Button key="delete" variant="primary" onClick={handleDelete}>
          <div className='flex'>
            {isDeletionPending && <Spinner size="small" />}
          <span className={`${isDeletionPending && 'ml-2'}`}>Delete</span>
          </div>
        </Button>
      ]}>
        {prompt}
        <p className="text-red-500 mt-4 ml-6">{isDeleteError && errorMessage}</p>
    </Modal>
  );
};
export default DeleteConfirmationModal;
