/* eslint-disable */
import { OptionList, Scrollable, Spinner } from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';

const ScrollableOptions = ({
  options,
  selected,
  setSelected,
  isLoading,
  isError,
  errorMessage,
  title,
  hasHeading = false
}) => {
  const [isAllSelected, setIsAllSelected] = useState(selected.includes('all'));

  const handleSelectionChange = useCallback(
    (newSelected) => {
      if (isAllSelected) {
        if (newSelected.includes('all')) {
          setIsAllSelected(false);
          setSelected(newSelected.filter((e) => e !== 'all'));
        } else {
          setIsAllSelected(false);
          setSelected([]);
        }
      } else {
        if (newSelected.includes('all')) {
          setIsAllSelected(true);
          setSelected(['all']);
        } else {
          setSelected(newSelected);
        }
      }
    },
    [isAllSelected, setSelected]
  );

  useEffect(() => {
    if (isAllSelected && !selected.includes('all')) {
      setIsAllSelected(false);
    }
  }, [isAllSelected, selected]);

  return (
    <div style={{ height: '300px' }}>
      <Scrollable shadow style={{ height: '100%' }}>
        {isLoading ? (
          <div className="flex items-center justify-center h-full">
            <Spinner size="small" />
          </div>
        ) : isError ? (
          <div className="flex items-center justify-center h-full">
            <p>{errorMessage}</p>
          </div>
        ) : (
          <>
            {hasHeading ? (
              options.flatMap((category, index) => (
                <div key={category.title || index}>
                  {category.title ? (
                    <h4 className="pl-3 pt-3 font-bold">{category.title}</h4>
                  ) : null}
                  <div className="pl-3">
                    <OptionList
                      options={category.options}
                      selected={selected}
                      onChange={handleSelectionChange}
                      allowMultiple
                    />
                  </div>
                  <hr />
                </div>
              ))
            ) : (
              <>
                {title ? <h4 className="pl-3 pt-3">{title}</h4> : null}
                <OptionList
                  options={options}
                  selected={selected}
                  onChange={handleSelectionChange}
                  allowMultiple
                />
              </>
            )}
          </>
        )}
      </Scrollable>
    </div>
  );
};

export default ScrollableOptions;
