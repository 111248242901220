import { api } from './index';
const resource = '/roles'

export const getRoles = async () => {
    try {
        const response = await api.get(`${resource}`)
        return response.data
    } catch (error) {
        throw new Error(error)
    }
}