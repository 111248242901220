const TIMEZONES = [
  'Europe/London',
  'Asia/Kolkata',
  'Europe/Istanbul',
  'Asia/Bangkok',
  'Asia/Kuala_Lumpur',
  'Asia/Kuching',
  'America/Puerto_Rico',
  'America/Port_of_Spain',
  'America/Aruba',
  'America/Curacao',
  'America/Barbados',
  'America/New_York',
  'America/Chicago',
  'America/Denver',
  'America/Los_Angeles',
  'America/Anchorage',
  'America/Honolulu',
  'Europe/Paris',
  'Europe/Berlin',
  'Europe/Moscow',
  'Europe/Lisbon'
];

const COUNTRY_PHONE_CODES = [
  '+44',
  '+91',
  '+90',
  '+66',
  '+60',
  '+1',
  '+1',
  '+297',
  '+599',
  '+1',
  '+1',
  '+1',
  '+1',
  '+1',
  '+1',
  '+1',
  '+1',
  '+33',
  '+49',
  '+7',
  '+351'
];

export const getTimezonesForSelect = () => {
  return TIMEZONES.map((timezone) => {
    return {
      label: timezone.replace(/_/g, ' '),
      value: timezone
    };
  });
};

export const getCountryPhoneCodeMap = () => {
  const result = {};
  TIMEZONES.map((timezone, index) => {
    result[timezone] = COUNTRY_PHONE_CODES[index];
  });
  return result;
};


export function getCountryCodeAndTimezone(phoneNumber) {
  if(!phoneNumber) return {
    countryCode: '+91',
    timezone: 'Asia/Kolkata',
    localNumber: ''
  };
  let countryCode = '';
  let timezone = '';
  let localNumber = '';

  // Extract the country code from the phone number
  for (let code of COUNTRY_PHONE_CODES) {
    if (phoneNumber.startsWith(code)) {
      countryCode = code;
      localNumber = phoneNumber.substring(code.length); // Remove the country code to get the local number
      break;
    }
  }

  // Find the timezone corresponding to the country code
  if (countryCode) {
    const index = COUNTRY_PHONE_CODES.indexOf(countryCode);
    timezone = TIMEZONES[index];
  }

  return {
    countryCode,
    timezone,
    localNumber // return the local number part without the country code
  };
}