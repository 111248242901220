import { Button, Spinner } from '@shopify/polaris';
import { Modal, notification } from 'antd';
import { useEffect, useState } from 'react';
import MsTeamsWebhookForm from './MsTeamsWebhookForm';
import { useMutation } from '@tanstack/react-query';
import { createMSTeamsWebhook, updateMSTeamsWebhook } from '../../api/msteamswebhooks.api';
import { isProtocolValid } from '../../utils';

const MsTeamsWebhookModal = ({
  isOpen,
  handleCancel = () => {},
  chosenTeamsWebhookData,
  setChosenTeamsWebhookData,
  setIsOpen,
  msTeamsWebhookCallback
}) => {
  const [api, contextHolder] = notification.useNotification();

  const [formData, setFormData] = useState({
    name: '',
    webhook_url: '',
    status: '1'
  });

  useEffect(() => {
    if (chosenTeamsWebhookData) {
      setFormData({
        name: chosenTeamsWebhookData.name,
        webhook_url: chosenTeamsWebhookData.webhook_url,
        status: chosenTeamsWebhookData.status ? '1' : '0'
      });
    } else {
      setFormData({
        name: '',
        webhook_url: '',
        status: '1'
      });
    }
  }, [chosenTeamsWebhookData, isOpen]);


  const { mutate, isPending: isModifyingMSTeamsWebhook } = useMutation({
    mutationFn: chosenTeamsWebhookData ? updateMSTeamsWebhook : createMSTeamsWebhook,
    onSuccess: () => {
      setIsOpen(false);
      setChosenTeamsWebhookData(null);
      msTeamsWebhookCallback.refetch();
    },
    onError: (error) => {
      api.error({
        message: error?.message || 'Something went wrong'
      });
    }
  });

  const handleOk = () => {
    if (!formData.name || !formData.webhook_url || !formData.status === undefined) {
      return api.warning({
        message: 'Make sure to fill all required fields'
      });
    }
    if (!isProtocolValid(formData.webhook_url)) {
      return api.warning({
        message: 'Invalid Webhook URL'
      });
    }
    mutate({
      id: chosenTeamsWebhookData?.id,
      data: {
        ...formData,
        name: formData.name,
        webhook_url: formData.webhook_url,
        status: formData.status === '1'
      }
    });
  };

  return (
    <>
      {contextHolder}
      <Modal
        title={chosenTeamsWebhookData ? 'Edit Teams Webhook' : 'Add New Teams Webhook'}
        open={isOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: true }}
        maskClosable={false}
        footer={[
          <div className={`inline mr-2`} key="cancel">
            <Button variant="secondary" onClick={handleCancel}>
              Cancel{' '}
            </Button>
          </div>,
          <div className="inline mr-2" key="submit">
            <Button variant="primary" onClick={handleOk} disabled={isModifyingMSTeamsWebhook}>
              <div className="flex items-center">
                Save
                {isModifyingMSTeamsWebhook && (
                  <span className="ml-2">
                    <Spinner size="small" />
                  </span>
                )}
              </div>
            </Button>
          </div>
        ]}>
        <form>
          <MsTeamsWebhookForm formData={formData} setFormData={setFormData} />
        </form>
      </Modal>
    </>
  );
};

export default MsTeamsWebhookModal;
