import { Autocomplete, LegacyStack, Tag } from '@shopify/polaris';
import { useState, useCallback } from 'react';

function NotifiyUser({ onTagsChange, availableOptions }) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  
  // Options that are available for selection
 

  // Handling text field change and filtering options
  const updateText = useCallback((value) => {
    if (value === '') {
      setSelectedOptions(availableOptions);
      return;
    }
    const filterRegex = new RegExp(value, 'i');
    const filteredOptions = availableOptions.filter(option => option.label.match(filterRegex));
    setSelectedOptions(filteredOptions);
  }, [availableOptions]);

  // Function to remove a tag
  const removeTag = useCallback(tag => {
    setSelectedOptions(selectedOptions.filter(option => option !== tag));
    onTagsChange(selectedOptions.filter(option => option !== tag));
  }, [selectedOptions, onTagsChange]);

  // Vertical content markup to display selected options
  const verticalContentMarkup = selectedOptions.length > 0 ? (
    <LegacyStack spacing="extraTight" alignment="center">
      {selectedOptions.map(option => (
        <Tag key={option} onRemove={() => removeTag(option)}>
          {option.replace('_', ' ').replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())}
        </Tag>
      ))}
    </LegacyStack>
  ) : null;

  const textField = (
    <Autocomplete.TextField
      onChange={(value) => updateText(value)}
      label="Choose Recipients for Violation Notification"
      placeholder="Select User"
      verticalContent={verticalContentMarkup}
      autoComplete="off"
    />
  );

  return (
    <div className=''>
      <Autocomplete
        allowMultiple
        options={availableOptions}
        selected={selectedOptions}
        textField={textField}
        onSelect={(selected) => {
          setSelectedOptions(selected);
          onTagsChange(selected);
        }}
      />
    </div>
  );
}

export default NotifiyUser;
