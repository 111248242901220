import { Select, TextField } from '@shopify/polaris';

const MsTeamsWebhookForm = ({ formData, setFormData }) => {
  return (
    <div>
      <style>
        {`.Polaris-TextField {
                margin-top: 10px;
            }`}
      </style>
      <div className="w-full">
        <div className="flex items-center mb-2">
          <span className="flex-1">
            Webhook Name<span className="text-red-500">*</span>
          </span>

          <span className="w-1/2 lg:w-3/4">
            <TextField
              value={formData.name}
              onChange={(value) => {
                if (value.length > 100) return;
                setFormData({ ...formData, name: value });
              }}
              labelHidden
              placeholder="Event Report Webhook"
            />
          </span>
        </div>

        <div className="flex items-center mb-2">
          <span className="flex-1">
            Webhook URL<span className="text-red-500">*</span>
          </span>

          <span className="w-1/2 lg:w-3/4">
            <TextField
              value={formData.webhook_url}
              onChange={(value) => {
                setFormData({ ...formData, webhook_url: value });
              }}
              labelHidden
              placeholder="https://visionifyai.webhook.office.com/webhook..."
            />
          </span>
        </div>

        <div className="flex items-center mb-2">
          <span className="flex-1">
            Status<span className="text-red-500">*</span>
          </span>

          <span className="w-1/2 lg:w-3/4">
            <Select
              options={[
                { label: 'Active', value: '1' },
                { label: 'Inactive', value: '0' }
              ]}
              onChange={(value) => {
                setFormData({ ...formData, status: value });
              }}
              value={formData.status}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default MsTeamsWebhookForm;
