/* eslint-disable */
import axios from 'axios';
import { resetStoreAndPersistor, store } from '../store/store';
import { logout } from '../store/slices/authSlice';
let baseUrl = new URL(window.location.href).origin;

const BASE_URL = process.env.REACT_APP_BASE_URL || baseUrl;
const EMAIL_BACKEND_URL = process.env.REACT_APP_EMAIL_BACKEND_URL || baseUrl;

// Function to set up common interceptors for all API instances
const setupInterceptors = (apiInstance) => {
  apiInstance.interceptors.request.use(
    (config) => {
      const { auth } = store.getState();
      if (auth.user) {
        if (!auth.user.company_id || !auth.user.user_id || !auth.chosenSite?.id) {
          // store.dispatch(logout());
          resetStoreAndPersistor();
          return Promise.reject(new Error('Missing IDs, logging out...'));
        }
        config.headers['company-id'] = auth.user.company_id;
        config.headers['user-id'] = auth.user.user_id;
        config.headers['site-id'] = auth.chosenSite?.id ?? 1;
        config.headers['Authorization'] = `Bearer ${auth.user.access_token}`;
      }
      return Promise.resolve(config);
    },
    (error) => Promise.reject(error)
  );

  apiInstance.interceptors.response.use(
    (response) => Promise.resolve(response),
    (error) => {
      if (error.response && error.response.status === 401) {
        // store.dispatch(logout());
        resetStoreAndPersistor();
      }
      return Promise.reject(error);
    }
  );
};

// Create API instances
const api = axios.create({
  baseURL: `${BASE_URL}/api`
});

const emailApi = axios.create({
  baseURL: `${EMAIL_BACKEND_URL}/notification-app/api/v1`
});

// Apply interceptors to both instances
setupInterceptors(api);
setupInterceptors(emailApi);

export { api, emailApi };
