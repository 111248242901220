/* eslint-disable no-unused-vars */
import { Badge, Card, DataTable, Link, Pagination, Spinner } from '@shopify/polaris';
import React from 'react';
import { useSelector } from 'react-redux';
import { formatTime } from '../../utils';
import { PAGE_SIZE } from './index';

export default function IncidentLog({
  events,
  areEventsLoading,
  incidentLogPageNumber,
  setIncidentLogPageNumber
}) {
  const {
    user: { timezone }
  } = useSelector((state) => state.auth);

  const rows = events?.events?.map((event) => {
    return [
      <Link external removeUnderline key={event.id} url={`/events/${event.id}`}>
        {formatTime(event.created_at, timezone, 'MMM DD, YYYY hh:mm A')}
      </Link>,
      event.area,
      <Badge
        key="severity"
        tone={
          event.severity === 'Low'
            ? 'success'
            : event.severity === 'Moderate'
              ? 'attention'
              : 'critical'
        }>
        {event.severity}
      </Badge>,
      event.review
    ];
  });

  return (
    <div>
      {areEventsLoading ? (
        <div className="flex justify-center items-center" style={{ height: '400px' }}>
          <Spinner accessibilityLabel="Loading chart" size="large" />
        </div>
      ) : (
        <div className="cus_table_design padding0">
          <div className="border rounded-md">
            <div className="flex items-center justify-between py-3 px-4">
              <h3 className="font-semibold text-[18px]">Incident Log</h3>
              <div className="flex flex-col justify-center items-center">
                <Pagination
                  hasNext={events.hasNext}
                  hasPrevious={incidentLogPageNumber > 1}
                  onNext={() => setIncidentLogPageNumber(incidentLogPageNumber + 1)}
                  onPrevious={() => setIncidentLogPageNumber(incidentLogPageNumber - 1)}
                />
                <span className="text-gray-400">{`${PAGE_SIZE * (incidentLogPageNumber - 1) + 1} - ${PAGE_SIZE * (incidentLogPageNumber - 1) + 1 + (events?.events?.length ?? 0) - 1} of ${events?.totalEvents ?? 0}`}</span>
              </div>
            </div>
            <DataTable
              verticalAlign="middle"
              columnContentTypes={['text', 'text', 'text', 'text']}
              headings={['Date & Time', 'Location', 'Severity', 'Review']}
              rows={rows}
            />
          </div>
        </div>
      )}
    </div>
  );
}
