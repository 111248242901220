import { useMutation, useQuery } from '@tanstack/react-query';
import { ArrowLeft, Circle, Pause } from 'lucide-react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getCameraById, getCameraStats, toggleCameraRecording } from '../../api/cameras.api';
import {
  Button,
  Spinner,
  Tooltip,
  Card,
  Select,
  LegacyCard,
  Tabs,
  DataTable,
  Icon
} from '@shopify/polaris';
import moment from 'moment';

import liveIco from '../../assets/live.svg';
import playIco from '../../assets/play.svg';

import Dateico from '../../assets/date.svg';
import { useCallback, useEffect, useState } from 'react';

import {
  deleteScenarioForCamera,
  getScenarios,
  startStopScenarioForCamera
} from '../../api/scenarios.api';
import { Modal, notification } from 'antd';
import { eventsByCameraId } from '../../api/events.api';
import EventCard from '../Events/EventCard';
import { useDispatch, useSelector } from 'react-redux';
import EventOverlay from '../../components/EventOverlay';
import { DeleteMajor, EditMajor } from '@shopify/polaris-icons';
import { setAllScenarios } from '../../store/slices/scenarioSlice';
import { getCameraStatusLogs } from '../../api/camera_status_logs.api';
import CameraStatusTracker from '../../components/CameraStatus/CameraStatusTracker';
import PieChart from '../../components/charts/Pie';
import { getRecordingsByCamera } from '../../api/cameras.api';
import RecordingCard from './RecordingCard';

const NUMBER_OF_EVENTS = 8;

const getStatusIndicator = (data) => {
  if (!data?.is_running) {
    return <div className="status-indicator w-2 h-2 rounded-full bg-[#F42020]"></div>;
  }

  const lastStatusTime = moment(data.last_running_status_at);
  const moreThanAnHourAgo = moment().diff(lastStatusTime, 'hours') > 1;

  if (data.is_running && moreThanAnHourAgo) {
    return <div className="status-indicator w-2 h-2 rounded-full bg-[#DADE0A]"></div>;
  }

  return <div className="status-indicator w-2 h-2 rounded-full bg-[#3fe674]"></div>;
};

const ScenarioInfo = ({ appliedScenarios, camera, refetch }) => {
  const { user } = useSelector((state) => state.auth);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [removeError, setRemoveError] = useState(false);
  // for deletion and loading states
  const [currentScenario, setCurrentScenario] = useState(null);

  const navigateTo = useNavigate();

  const { mutate: mutateRemoveScenario, isPending: isUpdatingStatus } = useMutation({
    mutationFn: () => deleteScenarioForCamera(camera.name, currentScenario.scenario_name),
    mutationKey: 'deleteScenarioForCamera',
    onSuccess: () => {
      setOpen(false);
      setConfirmLoading(false);
    },
    onError: () => {
      setRemoveError(true);
    },
    onSettled: () => {
      refetch();
      setCurrentScenario(null);
    }
  });

  const showModal = (scenario) => {
    setCurrentScenario(scenario);
    setOpen(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    if (currentScenario) {
      mutateRemoveScenario(camera.name, currentScenario.scenario_name);
    }
  };

  const handleCancel = () => {
    setOpen(false);
    setRemoveError(false);
  };

  const constructRows = useCallback(() => {
    let rows = [];
    appliedScenarios.forEach((scenario) => {
      rows.push([
        <div key={scenario.id} className="text-[#303030] flex items-center gap-2 text-[14px]">
          {getStatusIndicator(scenario)}
          <p>{scenario?.scenario_name} </p>
        </div>,
        <div key={`${scenario.id}-actions`} className="flex items-center ">
          <span className="mr-2">
            <Button
              disabled={user.role_id === 6}
              onClick={() => showModal(scenario)}
              tone="critical"
              variant="primary"
            >
              <Icon source={DeleteMajor} />
            </Button>
          </span>
          <span>
            <Button
              disabled={user.role_id === 6}
              onClick={() => {
                navigateTo(
                  `/apply-scenario?appliedScenarioId=${scenario.id}&camera=${camera.name}&cameraId=${camera.id}&scenario=${scenario.scenario_name}-beta`
                );
              }}
              tone="subdued"
              variant="secondary"
            >
              <Icon source={EditMajor} />
            </Button>
          </span>
        </div>
      ]);
    });
    return rows;
  }, [appliedScenarios]);

  if (!appliedScenarios || appliedScenarios.length === 0)
    return (
      <div>
        <p>No Scenarios Enabled</p>
      </div>
    );

  return (
    <div>
      <DataTable
        columnContentTypes={['text', 'text']}
        headings={['Scenario', 'Actions']}
        rows={constructRows()}
      />
      <Modal
        title={'Remove Scenario'}
        open={open}
        onOk={handleOk}
        footer={[
          <div className="inline mr-2" key="back">
            <Button variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
          </div>,
          <div className="inline mr-2" key="submit">
            <Button variant="primary" onClick={() => handleOk()}>
              <div className="flex items-center">
                Submit
                {isUpdatingStatus && (
                  <span className="ml-2">
                    <Spinner size="small" />
                  </span>
                )}
              </div>
            </Button>
          </div>
        ]}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <p>Are you sure you want to remove this scenario? This action is irreversable!</p>
        {removeError && (
          <p className="text-red-500">* Error removing scenario. Please try again later.</p>
        )}
      </Modal>
    </div>
  );
};

const CameraDetails = () => {
  const [api, contextHolder] = notification.useNotification();
  const { id } = useParams();
  const navigateTo = useNavigate();

  const [statisticsSelected, setStatisticSelected] = useState('LAST_24_HOURS');
  const handleSelectChange = useCallback((value) => setStatisticSelected(value), []);

  const [loadableScenario, setLoadableScenario] = useState(null);

  const [scenarioOptions, setScenarioOptions] = useState([{ label: 'All', value: 'All' }]);
  const [chosenScenarioOption, setChosenScenarioOption] = useState('All');
  const handleScenarioSelectChange = useCallback((value) => setChosenScenarioOption(value), []);

  const { chosenEventIdx } = useSelector((state) => state.event);
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const {
    data: availableScenarios,
    isLoading: fetchingAllScenarios,
    isSuccess: fetchedAllScenarios,
    isError: couldNotFetchScenarios
  } = useQuery({
    queryKey: ['allScenarios'],
    queryFn: getScenarios
  });

  useEffect(() => {
    if (!fetchingAllScenarios && !couldNotFetchScenarios && fetchedAllScenarios) {
      dispatch(setAllScenarios(availableScenarios?.scenarios));
    }
  }, [fetchedAllScenarios, couldNotFetchScenarios, fetchedAllScenarios]);

  const {
    data: camera,
    isLoading,
    isError,
    refetch
  } = useQuery({
    queryKey: ['cameraInfo', id],
    queryFn: () => getCameraById(id)
  });

  const { data: cameraStatistics, isLoading: areStatisticsLoading } = useQuery({
    queryKey: ['cameraStats', id, statisticsSelected],
    queryFn: () => getCameraStats(id, statisticsSelected)
  });

  const { data: events, isLoading: areEventsLoading } = useQuery({
    queryKey: ['events', 'id', chosenScenarioOption],
    queryFn: () => eventsByCameraId(id, chosenScenarioOption, 1, NUMBER_OF_EVENTS)
  });

  const { data: cameraStatusLogs, isLoading: isCameraStatusLogLoading } = useQuery({
    queryKey: ['cameraStatusLogs', id, statisticsSelected],
    queryFn: () => getCameraStatusLogs({ cameraName: camera.name, timeRange: statisticsSelected }),
    enabled: !!camera?.name
  });

  const formatDataForPieChart = (cameraStatusLogs) => {
    if (!cameraStatusLogs || cameraStatusLogs.length === 0)
      return { labels: [], values: [], formattedValues: [] };

    if (cameraStatusLogs.length === 1) {
      const camera = cameraStatusLogs[0];

      const labels = ['Uptime', 'Downtime'];
      const values = [camera.summary.uptime, camera.summary.downtime];
      const formattedValues = [
        convertMillisecondsToReadableFormat(camera.summary.uptime),
        convertMillisecondsToReadableFormat(camera.summary.downtime)
      ];

      return { labels, values, formattedValues };
    } else {
      let totalUptime = 0;
      let totalDowntime = 0;

      cameraStatusLogs.forEach((camera) => {
        totalUptime += camera.summary.uptime;
        totalDowntime += camera.summary.downtime;
      });

      const labels = ['Total Uptime', 'Total Downtime'];
      const values = [totalUptime, totalDowntime];
      const formattedValues = [
        convertMillisecondsToReadableFormat(totalUptime),
        convertMillisecondsToReadableFormat(totalDowntime)
      ];

      return { labels, values, formattedValues };
    }
  };

  const convertMillisecondsToReadableFormat = (ms) => {
    const totalSeconds = Math.floor(ms / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    // const seconds = totalSeconds % 60;

    return `${hours}h ${minutes}m`;
  };

  useEffect(() => {
    if (!areEventsLoading) {
      setScenarioOptions(() => {
        const newScenarioOptions = [{ label: 'All', value: 'All' }];
        events.scenarios.forEach((scenario) => {
          newScenarioOptions.push({ label: scenario, value: scenario });
        });
        return newScenarioOptions;
      });
    }
  }, [events, areEventsLoading]);

  const { mutate: toggleScenario, isPending: isTogglingScenario } = useMutation({
    mutationFn: async (data) => {
      setLoadableScenario(data.renderId);
      const delayPromise = new Promise((resolve) => setTimeout(resolve, 3000));
      await Promise.all([delayPromise]);
      return startStopScenarioForCamera(data.scenarioId, data.checked);
    },
    onSuccess: () => {
      return refetch();
    },
    onError: () => {
      return api.error({ message: 'Unable to toggle scenario. Please try again later.' });
    },
    onSettled: () => {
      return setLoadableScenario(null);
    }
  });

  const { mutate: toggleCameraRecordingApi, isPending: isTogglingRecording } = useMutation({
    mutationFn: async (id) => {
      await new Promise((r) => setTimeout(r, 3000));
      return await toggleCameraRecording(id);
    },
    onSuccess: () => {
      return refetch();
    },
    onError: () => {
      return api.error({ message: 'Unable to toggle scenario. Please try again later.' });
    }
  });

  const [selected, setSelected] = useState(0);
  const handleTabChange = useCallback((selectedTabIndex) => setSelected(selectedTabIndex), []);

  const tabs = [
    {
      id: 'events',
      content: 'Events',
      accessibilityLabel: 'All customers',
      panelID: 'events'
    },
    {
      id: 'scenarios',
      content: 'Scenarios',
      panelID: 'scenarios'
    },
    {
      id: 'cameraStatus',
      content: 'Camera Status',
      panelID: 'cameraStatus'
    },
    {
      id: 'recordings',
      content: 'Recordings',
      panelID: 'recordings'
    }
  ];

  const options = [
    { label: 'Today', value: 'LAST_24_HOURS' },
    { label: 'Last 7 Days', value: 'LAST_7_DAYS' },
    { label: 'Last 30 Days', value: 'LAST_30_DAYS' }
  ];

  const { data: cameraRecordings, isLoading: isLoadingCameraRecordings } = useQuery({
    queryKey: ['cameraRecordings', id],
    queryFn: () => getRecordingsByCamera(id, 1, 10),
    enabled: selected === 3, 
  });

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <Spinner size="large" />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex justify-center items-center min-h-screen text-center">
        <p>Camera not found. Please check the ID or your network connection.</p>
      </div>
    );
  }

  return (
    <>
      {chosenEventIdx ? (
        <EventOverlay refetch={null} showOne={true} />
      ) : (
        <div className="max-h-screen">
          {contextHolder}
          <div className="bg-white h-[3rem] flex items-center text-[13px] p-2">
            <div className="rounded-md hover:opacity-50 hover:bg-[#D4D4D4]">
              <ArrowLeft size={24} className="cursor-pointer" onClick={() => navigateTo(-1)} />
            </div>
            <p className="font-semibold text-lg ml-4">{camera.name}</p>
          </div>
          <div className="py-4 px-4">
            <div className="flex flex-col md:flex-row md:justify-around gap-4">
              <div className="w-full md:w-3/5">
                <img src={camera.thumbnail_url} alt={`${camera.name} image`} />
              </div>
              <div className="w-full md:w-2/5">
                <div className="bg-white rounded-md shadow-xl mb-4">
                  <div className="flex items-center justify-between p-4">
                    <h3 className="font-semibold text-[16px] pb-2">Applied Scenarios</h3>
                  </div>
                  <hr />
                  <div className="py-4 space-y-5 px-4">
                    {camera.applied_scenarios.length ? (
                      camera.applied_scenarios.map((data, i) => (
                        <div key={i} className="flex items-center justify-between gap-3 flex-wrap">
                          <div className="text-[#303030] flex items-center gap-2 text-[14px]">
                            {getStatusIndicator(data)}
                            <p>{data?.scenario_name} </p>
                          </div>
                          <div className="flex items-center gap-3">
                            <Tooltip content="Coming soon">
                              <Button disabled>
                                <img src={liveIco} className="w-6" alt="" />
                              </Button>
                            </Tooltip>
                            {isTogglingScenario && loadableScenario === i && (
                              <Button disabled>
                                <Spinner size="small" />
                              </Button>
                            )}
                            {!isTogglingScenario && !data.is_active ? (
                              <Button
                                disabled={isTogglingScenario || user.role_id === 6}
                                onClick={() => {
                                  toggleScenario({
                                    scenarioId: data.id,
                                    checked: !data.is_active,
                                    renderId: i
                                  });
                                }}
                              >
                                <img src={playIco} className="w-6" alt="" />
                              </Button>
                            ) : (
                              <Button
                                disabled={isTogglingScenario || user.role_id === 6}
                                onClick={() => {
                                  toggleScenario({
                                    scenarioId: data.id,
                                    checked: !data.is_active,
                                    renderId: i
                                  });
                                }}
                              >
                                <Pause />
                              </Button>
                            )}
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="flex justify-center">
                        <p>No Scenarios have been applied on this camera.</p>
                      </div>
                    )}
                    <div className="text-end">
                      {user.role_id !== 6 && ( // TODO: Change global-user access to a dynamic methodology
                        <div className="flex items-center justify-end">
                          <span
                            onClick={() => {
                              toggleCameraRecordingApi(id);
                            }}
                            className="mr-2 border p-2 rounded-md shadow-lg cursor-pointer hover:shadow-xl hover:bg-slate-200 hover:opacity-70"
                          >
                            {isTogglingRecording ? (
                              <Spinner size="small" />
                            ) : camera.is_recording ? (
                              <Pause size={16} fill="black" />
                            ) : (
                              <Circle size={16} color="red" strokeWidth={4} />
                            )}
                          </span>
                          <Link to={`/scenarios?camera=${camera.name}&cameraId=${camera.id}`}>
                            <Button variant="primary">Add New Scenario</Button>
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <Card>
                  <div className="flex items-center justify-between pb-4">
                    <h3 className="font-semibold text-[16px] pb-2">Statistics</h3>
                    <Select
                      label="Sort By:"
                      labelInline
                      options={options}
                      onChange={handleSelectChange}
                      value={statisticsSelected}
                    />
                  </div>
                  <hr />
                  <div className="py-4 grid grid-cols-1 2xl:grid-cols-2 gap-5">
                    <div>
                      <Card>
                        {areStatisticsLoading ? (
                          <>
                            <Spinner size="small" />
                          </>
                        ) : (
                          <div className="grid grid-cols-3 items-start justify-between">
                            <div className="col-span-2 z-50">
                              <h4 className="text-[16px] text-[#1A1A1A] font-semibold pt-3">
                                Total Events
                              </h4>
                              <h2 className="text-[30px] text-[#1A1A1A] font-bold pt-5">
                                {cameraStatistics?.eventCount ?? 0}
                              </h2>
                            </div>
                            <div className="ico flex justify-end">
                              <div className="bg-[#303030] p-2 rounded-lg">
                                <img src={Dateico} />
                              </div>
                            </div>
                          </div>
                        )}
                      </Card>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </div>
          <div className="px-3.5">
            <div>
              <LegacyCard>
                <div className="flex justify-between gap-5 flex-wrap">
                  <div className="py-2 px-2">
                    <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}></Tabs>
                  </div>
                </div>
                <hr />
                {selected === 0 && (
                  <LegacyCard.Section>
                    <div className="eventlist py-4">
                      <div className="flex items-center justify-between pb-4">
                        <p className="font-semibold">
                          Showing the {NUMBER_OF_EVENTS} most recent events
                        </p>

                        <Select
                          labelInline
                          options={scenarioOptions}
                          value={chosenScenarioOption}
                          label="Scenario:"
                          onChange={handleScenarioSelectChange}
                        />
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-5">
                        {events && events.data && events.data.length > 0 ? (
                          events?.data?.map((data) => <EventCard key={data.id} event={data} />)
                        ) : (
                          <div className="">
                            <p>No Events Available</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </LegacyCard.Section>
                )}
                {selected === 1 && (
                  <div className="py-4 px-4 w-full xl:w-[50%] paddy-none">
                    <ScenarioInfo
                      camera={camera}
                      refetch={refetch}
                      appliedScenarios={camera.applied_scenarios}
                    />
                  </div>
                )}
                {selected === 2 && (
                  <div className="py-4 px-4 w-full xl:w-[80%]">
                    {isCameraStatusLogLoading ? (
                      <div className="flex justify-center items-center min-h-screen">
                        <Spinner size="small" />
                      </div>
                    ) : (
                      <>
                        {cameraStatusLogs && cameraStatusLogs.length > 0 ? (
                          <div className="space-y-6">
                            {/* Camera Status Tracker */}
                            <div className="w-full">
                              <CameraStatusTracker
                                cameraStatusLogs={cameraStatusLogs}
                                timeRange={statisticsSelected}
                              />
                            </div>
                            {/* Pie Chart */}
                            <div className="flex flex-col lg:flex-row justify-between gap-2 mb-4">
                              <div className="w-2/3">
                                <PieChart
                                  isLoading={isCameraStatusLogLoading}
                                  data={formatDataForPieChart(cameraStatusLogs)}
                                  name={'Camera Uptime vs. Downtime'}
                                  customColors={['#4CAF50', '#F44336']}
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <p>No data available</p>
                        )}
                      </>
                    )}
                  </div>
                )}
                {selected === 3 && (
                  <LegacyCard.Section>
                    {isLoadingCameraRecordings ? (
                      <Spinner size="small" />
                    ) : cameraRecordings?.data && cameraRecordings.data.length > 0 ? (
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {cameraRecordings.data.map((rec) => (
                          <RecordingCard key={rec.id} recording={rec} />
                        ))}
                      </div>
                    ) : (
                      <p>No recordings available for this camera</p>
                    )}
                  </LegacyCard.Section>
                )}
              </LegacyCard>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CameraDetails;
