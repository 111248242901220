/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { Badge, Button, Card } from '@shopify/polaris';
import { Icons } from '../../Icons';
import { MobileBackArrowMajor } from '@shopify/polaris-icons';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getCameras } from '../../api/cameras.api';
import { Edit, Video } from 'lucide-react';
import { Modal } from 'antd';
import NotifiyUser from './notify-user';

const AreaDetail = () => {
  const [isTracking, setIsTracking] = useState(false); // To track if the timer is running
  const [elapsedTime, setElapsedTime] = useState(0); // The actual timer value
  const [countdown, setCountdown] = useState(null); // Countdown value for the starting timer
  const [showCountdown, setShowCountdown] = useState(false); // To show/hide countdown overlay
  const navigateTo = useNavigate();

  const currentPath = window.location.pathname;
  const pathSegments = currentPath.split('/');
  const areaId = pathSegments[pathSegments.length - 1];

  useEffect(() => {
    // Load the saved time from localStorage on component mount
    const savedElapsedTime = localStorage.getItem(`elapsedTime_${areaId}`);
    if (areaId == 2) {
      setElapsedTime(250);
    }
    if (savedElapsedTime) {
      setElapsedTime(parseInt(savedElapsedTime, 10)); // Restore the saved elapsed time
    }

    // If the areaId is 2, automatically start tracking
    if (areaId == 2) {
      setIsTracking(true);
    }
  }, [areaId]);

  useEffect(() => {
    let timer;
    if (isTracking) {
      // Timer runs and updates elapsedTime every second
      timer = setInterval(() => {
        setElapsedTime((prevTime) => prevTime + 1); // Increment time every second
      }, 1000);
    }

    return () => clearInterval(timer); // Clear timer on unmount or when paused
  }, [isTracking]);

  useEffect(() => {
    let countdownTimer;
    if (countdown !== null && countdown > 0) {
      countdownTimer = setInterval(() => {
        setCountdown((prev) => prev - 1); // Countdown decrement
      }, 800); // Set to 800ms countdown interval
    } else if (countdown === 0) {
      setShowCountdown(false); // Hide countdown overlay
      setIsTracking(true); // Start the actual timer after countdown
    }

    return () => clearInterval(countdownTimer); // Clear countdown timer on unmount or stop
  }, [countdown]);

  const startCountdown = () => {
    if (!isTracking) {
      setCountdown(5); // Start countdown if the timer is not running
      setShowCountdown(true); // Show the countdown overlay
      setElapsedTime(0); // Reset the elapsed time
      localStorage.setItem(`elapsedTime_${areaId}`, elapsedTime);
    }
  };

  const handlePlayPauseClick = () => {
    if (isTracking) {
      // When the timer is running (pause button clicked)
      setIsTracking(false); // Pause tracking
      localStorage.setItem(`elapsedTime_${areaId}`, elapsedTime); // Save the elapsed time to localStorage
    } else {
      // When the timer is paused (play button clicked)
      const savedElapsedTime = localStorage.getItem(`elapsedTime_${areaId}`);
      if (savedElapsedTime) {
        setElapsedTime(parseInt(savedElapsedTime, 10)); // Restore the saved elapsed time
      }
      startCountdown(); // Start the countdown before resuming
    }
  };

  const formatTime = (seconds) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = seconds % 60;
    return `${h}h ${m}m ${s}s`;
  };

  const Statistics = [
    {
      title: 'Run Time',
      value: formatTime(elapsedTime),
      showIcons: false,
      flexLayout: false
    },
    {
      title: 'Total Violations',
      value: areaId == 2 ? '4' : '0',
      showIcons: false,
      flexLayout: false
    },
    {
      title: 'Tracking',
      showIcons: true,
      flexLayout: true
    }
  ];

  const Violation = [
    {
      title: 'Cam 1',
      imageSrc:
        'https://c7.alamy.com/comp/2C3GN64/view-of-the-wing-and-engine-of-the-aircraft-repair-in-the-hangar-2C3GN64.jpg',
      time: '03:02 PM',
      badgeText: 'Restricted Area',
      shift: 'Shift 1'
    },
    {
      title: 'Cam 2',
      imageSrc:
        'https://c7.alamy.com/comp/2A2CX33/opened-aircraft-engine-in-the-hangar-maintenance-wing-view-2A2CX33.jpg',
      time: '03:10 PM',
      badgeText: 'Restricted Area',
      shift: 'Shift 2'
    },
    {
      title: 'Cam 3',
      imageSrc:
        'https://c7.alamy.com/comp/2A2CX33/opened-aircraft-engine-in-the-hangar-maintenance-wing-view-2A2CX33.jpg',
      time: '03:15 PM',
      badgeText: 'Restricted Area',
      shift: 'Shift 3'
    },
    {
      title: 'Cam 4',
      imageSrc:
        'https://c7.alamy.com/comp/2C3GN64/view-of-the-wing-and-engine-of-the-aircraft-repair-in-the-hangar-2C3GN64.jpg',
      time: '03:20 PM',
      badgeText: 'Restricted Area',
      shift: 'Shift 1'
    }
  ];
  const { data: cameraData, isLoading: cameraLoading } = useQuery({
    queryKey: ['cameras'],
    queryFn: getCameras
  });

  const getAreaTitle = (id) => {
    switch (parseInt(id, 10)) {
      case 1:
        return 'Areas 1';
      case 2:
        return 'Areas 2';
      case 3:
        return 'Areas 3';
      default:
        return 'Unknown Area';
    }
  };
  const getAreaCamera = (id) => {
    switch (parseInt(id, 10)) {
      case 1:
        return 'Area 1';
      case 2:
        return 'Area 2';
      case 3:
        return 'Area 3';
      default:
        return 'Unknown Area';
    }
  };

  const [showDidYouSaveModal, setShowDidYouSaveModal] = useState(false);

  const handleOk = () => {
    setShowDidYouSaveModal(false);
  };
  const handleCancel = () => {
    setShowDidYouSaveModal(false);
  };

  const [selectedTags, setSelectedTags] = useState([]);

  const handleTagsChange = (newTags) => {
    setSelectedTags(newTags);
    // You can also perform other actions here when tags change
  };

  const availableOptions = [
    { value: 'liam_(+14081234568)', label: 'Liam (+14081234568)' },
    {
      value: 'sophia_(sophia.williams@visionify.ai)',
      label: 'Sophia (sophia.williams@visionify.ai)'
    },
    { value: 'jack_(+13105551235)', label: 'Jack (+13105551235)' },
    { value: 'amelia_(amelia.clark@visionify.ai)', label: 'Amelia (amelia.clark@visionify.ai)' },
    { value: 'elijah_(+12135557655)', label: 'Elijah (+12135557655)' },
    {
      value: 'charlotte_(charlotte.johnson@visionify.ai)',
      label: 'Charlotte (charlotte.johnson@visionify.ai)'
    },
    { value: 'oliver_(oliver_smith@visionify.ai)', label: 'Oliver (oliver_smith@visionify.ai)' },
    { value: 'harper_(+16175559877)', label: 'Harper (+16175559877)' },
    { value: 'mason_(mason.davis@visionify.ai)', label: 'Mason (mason.davis@visionify.ai)' },
    { value: 'evelyn_(+15105556790)', label: 'Evelyn (+15105556790)' },
    { value: 'logan_(logan.miller@visionify.ai)', label: 'Logan (logan.miller@visionify.ai)' },
    {
      value: 'abigail_(abigail.moore@visionify.ai)',
      label: 'Abigail (abigail.moore@visionify.ai)'
    },
    { value: 'lucas_(+14085551234)', label: 'Lucas (+14085551234)' },
    { value: 'mia_(mia.thompson@visionify.ai)', label: 'Mia (mia.thompson@visionify.ai)' }
  ];

  const filteredCameras = cameraData?.data.filter((camera) => {
    return (
      camera.applied_scenarios &&
      camera.applied_scenarios.some((scenario) => scenario.scenario_name === 'Restricted Area')
    );
  });

  console.log('filteredCameras', filteredCameras);
  console.log({ isTracking });
  return (
    <>
      {showCountdown && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-[9999]">
          <div className="text-center">
            <h1 className="text-white text-2xl font-semibold">Tracking will Start In</h1>
            <h1 className="text-white text-8xl font-bold pt-5">{countdown}</h1>
          </div>
        </div>
      )}

      <div className="radiousnone main-title">
        <div className="bg-white py-3 px-3 shadow-md border-b">
          <div className="title smallbtn flex items-center gap-3">
            <Link to="/areas">
              <MobileBackArrowMajor className="w-6" />
            </Link>
            <h3 className="text-black text-lg font-semibold">{getAreaTitle(areaId)}</h3>
            <Video
              size={20}
              color={isTracking ? 'green' : 'red'}
              fill={isTracking ? 'green' : 'red'}
              title={isTracking ? 'Tracking On' : 'Tracking Off'}
              className={isTracking ? 'animate-pulse' : ''}
            />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 pt-5 px-5 cardheight">
        {Statistics.map((card, index) => (
          <Card key={index} padding={0}>
            <div
              className={`py-5 px-5 ${
                card.flexLayout ? 'flex items-center justify-between h-full flex-wrap gap-3' : ''
              }`}>
              <h5 className="text-[22px] font-medium text-black">{card.title}</h5>

              {card.showIcons ? (
                <div className="flex items-center gap-5">
                  <div onClick={handlePlayPauseClick} className="cursor-pointer">
                    {isTracking ? (
                      <Icons.pauseico className="w-10 h-10" fill="#fff" />
                    ) : (
                      <Icons.playico className="w-10 h-10" fill="#fff" />
                    )}
                  </div>
                  <Link to="/areas-config/apply/27982352-1f09-4910-bfae-f30e52911b8a/restricted-area">
                    <Icons.configico className="w-10 h-10" fill="#fff" />
                  </Link>
                  <div onClick={() => setShowDidYouSaveModal(true)} className="cursor-pointer">
                    <Icons.usergroupico className="w-10 h-10" fill="#fff" />
                  </div>
                </div>
              ) : (
                <h2 className="text-[34px] text-black font-bold mt-8">
                  {card.title === 'Run Time' ? formatTime(elapsedTime) : card.value}
                </h2>
              )}
            </div>
          </Card>
        ))}
      </div>
      <div className="py-5 px-5">
        <Card padding={0}>
          <div className="border-b py-4 px-5">
            <h3 className="text-[20px] font-semibold">{getAreaCamera(areaId)}</h3>
          </div>
          {/* <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 py-5 px-5 cardheight"> */}
          <div className="p-4">
            {filteredCameras && filteredCameras.length > 0 ? (
              <div className="flex flex-wrap gap-x-4 gap-y-8">
                {filteredCameras.map((camera) => (
                  <div
                    key={camera.id}
                    className="relative h-auto w-fit shadow-sm border cardheight">
                    <div
                      className="h-[214px] w-[320px] hover:opacity-50 cursor-pointer"
                      onClick={() => navigateTo(`/cameras/${camera.id}`, { state: { camera } })}>
                      <img
                        src={camera.thumbnail_url}
                        alt="camera"
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <div className="flex justify-between text-[#242424] text-sm font-semibold bg-white px-2 py-2 w-full h-auto rounded-b-md shadow-md">
                      <span
                        className="hover:underline cursor-pointer"
                        onClick={() => navigateTo(`/cameras/${camera.id}`, { state: { camera } })}>
                        {camera.name.length > 20
                          ? camera.name.substring(0, 20) + '...'
                          : camera.name}
                      </span>
                      <div className="flex">
                        <div
                          className={`mr-2 cursor-pointer `}
                          onClick={() => {
                            const scenario = camera.applied_scenarios.find(
                              (scenario) => scenario.scenario_name === 'Restricted Area'
                            );
                            console.log('camera', camera);
                            console.log('scenario', scenario);
                            navigateTo(
                              `/scenarios/edit/${scenario.id}/restricted-area/${camera?.id}`,
                              { state: { scenario, camera } }
                            );
                          }}>
                          <Edit size={20} color="#000" />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center text-center py-10 w-full">
                <p className="text-lg mb-2">
                  No cameras found with the &quot;<span className="font-bold">Restricted Area</span>
                  &quot; scenario applied.
                </p>
                <p className="mb-4">Please configure cameras to use this scenario.</p>
                <Button
                  onClick={() =>
                    navigateTo(
                      '/areas-config/apply/27982352-1f09-4910-bfae-f30e52911b8a/restricted-area'
                    )
                  }
                  primary>
                  Configure Cameras
                </Button>
              </div>
            )}
          </div>
        </Card>
      </div>
      {areaId == 2 ? (
        <div className="py-5 px-5">
          <Card padding={0}>
            <div className="border-b py-4 px-5">
              <h3 className="text-[20px] font-semibold">Violation</h3>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-5 py-5 px-5 cardheight">
              <>
                {Violation.map((violation, index) => (
                  <Card key={index} padding="0">
                    <div className="relative">
                      <img
                        src={violation.imageSrc}
                        alt={violation.title}
                        className="w-full h-full object-cover"
                      />
                      {/* Play Icon positioned at the center of the image */}
                      <Icons.playico
                        className="w-8 h-8 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                        fill="#fff"
                      />
                    </div>
                    <div className="flex items-center justify-between pt-3 px-3">
                      <div className="flex items-center gap-2">
                        <Icons.CameraIco className="w-6 h-6" fill="#303030" />
                        <h2 className="font-semibold text-base">{violation.title}</h2>
                      </div>
                      {/* Timestamp for the violation */}
                      <p className="text-sm">{violation.time}</p>
                    </div>
                    <div className="flex items-center justify-between py-3 px-3">
                      {/* Badge component for pathway */}
                      <Badge tone="info" size="large">
                        {violation.badgeText}
                      </Badge>
                      {/* Shift label */}
                      <p className="text-sm">{violation.shift}</p>
                    </div>
                  </Card>
                ))}
              </>
            </div>
          </Card>
        </div>
      ) : (
        ''
      )}

      <Modal
        title="Violation Alert Configuration"
        open={showDidYouSaveModal}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: true }}
        maskClosable={false}
        footer={[
          <div className={`inline mr-2`} key="cancel">
            <Button variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
          </div>,
          <div className="inline mr-2" key="submit">
            <Button variant="primary" onClick={handleOk} disabled={false}>
              <div className="flex items-center">Save</div>
            </Button>
          </div>
        ]}>
        <div className="mt-5">
          <NotifiyUser onTagsChange={handleTagsChange} availableOptions={availableOptions} />
        </div>
      </Modal>
    </>
  );
};

export default AreaDetail;
