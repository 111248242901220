import { api } from './index';

const resource = '/speakers';

export const getSpeakers = async (page = 1, size = 10) => {
  try {
    const response = await api.get(`${resource}?page=${page}&size=${size}`);
    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.error || 'Failed to fetch speakers. Please try again later.'
    );
  }
};

export const getSpeakerById = async (id) => {
  try {
    const response = await api.get(`${resource}/${id}`);
    return response.data.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.error || 'Failed to fetch speaker details. Please try again later.'
    );
  }
};

export const createSpeaker = async (speakerData) => {
  try {
    const response = await api.post(`${resource}`, speakerData);
    return response.data;
  } catch (error) {
    console.error('Error creating speaker:', error);
    throw new Error(
      error.response?.data?.error || 'Failed to create speaker. Please try again later.'
    );
  }
};

export const updateSpeaker = async (id, speakerData) => {
  try {
    const response = await api.put(`${resource}/${id}`, speakerData);
    return response.data;
  } catch (error) {
    console.error('Error updating speaker:', error);
    throw new Error(
      error.response?.data?.error || 'Failed to update speaker. Please try again later.'
    );
  }
};

export const deleteSpeaker = async (id) => {
  try {
    const response = await api.delete(`${resource}/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting speaker:', error);
    throw new Error(
      error.response?.data?.error || 'Failed to delete speaker. Please try again later.'
    );
  }
};
