import { api } from './index';
const resource = '/sites';

export const getSites = async () => {
  try {
    const response = await api.get(`${resource}`);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getSiteInfo = async ({ id, payload }) => {
  try {
    const response = await api.post(`${resource}/events/${id}`, { ...payload });
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const createSite = async (data) => {
  try {
    const payload = { ...data };
    payload.face_blur = data.face_blur ? 1 : 0;
    payload.full_body_blur = data.full_body_blur ? 1 : 0;
    payload.stick_figure = data.stick_figure ? 1 : 0;
    const response = await api.post(`${resource}`, payload);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const updateSite = async ({ id, data }) => {
  try {
    const payload = { ...data };
    payload.face_blur = data.face_blur ? 1 : 0;
    payload.full_body_blur = data.full_body_blur ? 1 : 0;
    payload.stick_figure = data.stick_figure ? 1 : 0;
    const response = await api.put(`${resource}/${id}`, payload);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};
