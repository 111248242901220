import { Button, Spinner, TextField, Select } from '@shopify/polaris';
import { Modal } from 'antd';

const EditUserModal = ({
  open,
  handleOk,
  handleCancel,
  editUserForm,
  editUserErrors,
  handleEditUserFieldChange,
  isPending,
  timezones,
  countryCodes,
  reinviteUser,
  resetPassword,
  roles,
  sites,
  setOpen,
  setShowDelete
}) => {
  return (
    <Modal
      title="Edit User"
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{ disabled: true }}
      cancelButtonProps={{ disabled: true }}
      maskClosable={false}
      footer={[
        <div className="inline mr-2" key="delete">
          <Button
            variant="primary"
            tone="critical"
            onClick={() => {
              setOpen(false);
              setShowDelete(true);
            }}>
            Delete User
          </Button>
        </div>,
        <div className={`inline mr-2 ${editUserForm.isVerified? '': 'hide'}`} key="reset">
          <Button
            variant="secondary"
            onClick={() => {
              resetPassword({ email: editUserForm.email });
            }}>
            Reset Password
          </Button>
        </div>,
        <div className={`inline mr-2 ${editUserForm.isVerified ? 'hide' : ''}`} key="reinvite">
          <Button
            variant="secondary"
            disabled={editUserForm.isVerified}
            onClick={() => {
              reinviteUser(editUserForm.username);
            }}>
            Resend Invite
          </Button>
        </div>,
        <div className="inline mr-2" key="submit">
          <Button variant="primary" onClick={handleOk}>
            <div className="flex items-center">
              Update
              {isPending && (
                <span className="ml-2">
                  <Spinner size="small" />
                </span>
              )}
            </div>
          </Button>
        </div>
      ]}>
      <form>
        <div className="w-full">
          <div className="flex items-center mb-2">
            <span className="flex-1">
              Full Name<span className="text-red-500">*</span>
            </span>

            <span className="w-1/2 lg:w-3/4">
              <TextField
                value={editUserForm.name}
                onChange={(value) => handleEditUserFieldChange(value, 'name')}
                labelHidden
                placeholder="Benjamin Bookcase"
              />
            </span>
          </div>
          <div className="flex items-center mb-2">
            <span className="flex-1">Username</span>

            <span className="w-1/2 lg:w-3/4">
              <TextField
                value={editUserForm.username}
                onChange={(value) => handleEditUserFieldChange(value, 'username')}
                labelHidden
                placeholder="bbookcase"
                disabled
              />
              {editUserErrors.existingUsername && (
                <span className="text-red-500 text-xs mt-2">Username already exists!</span>
              )}
            </span>
          </div>
          <div className="flex items-center mb-2">
            <span className="flex-1">Email Address</span>

            <span className="w-1/2 lg:w-3/4">
              <TextField
                value={editUserForm.email}
                onChange={(value) => handleEditUserFieldChange(value, 'email')}
                labelHidden
                placeholder="bbookcase@visionify.ai"
                disabled
              />
              {editUserErrors.existingEmail && (
                <span className="text-red-500 text-xs mt-2">Email already exists!</span>
              )}
            </span>
          </div>
          <div className="flex items-center mb-2">
            <span className="flex-1">
              Timezone<span className="text-red-500">*</span>
            </span>

            <span className="w-1/2 lg:w-3/4">
              <Select
                onChange={(value) => handleEditUserFieldChange(value, 'timezone')}
                value={editUserForm.timezone}
                labelHidden
                options={timezones}
              />
            </span>
          </div>
          <div className="flex items-center mb-2">
            <span className="flex-1">
              Role<span className="text-red-500">*</span>
            </span>

            <span className="w-1/2 lg:w-3/4">
              <Select
                onChange={(value) => handleEditUserFieldChange(value, 'role')}
                value={editUserForm.role}
                labelHidden
                options={roles}
              />
            </span>
          </div>
          <div className="flex items-center mb-2">
            <span className="flex-1">
              Site <span className="text-red-500">*</span>
            </span>

            <span className="w-1/2 lg:w-3/4">
              <Select
                onChange={(value) => handleEditUserFieldChange(value, 'site')}
                value={editUserForm.site}
                labelHidden
                options={sites}
              />
            </span>
          </div>
          <div className="flex items-center mb-2">
            <span className="flex-1">
              Phone<span className="text-red-500">*</span>
            </span>

            <span className="w-1/2 lg:w-3/4">
              <TextField
                type="tel"
                value={editUserForm.phone?? ''}
                onChange={(value) => {
                  if (isNaN(value)) return;
                  handleEditUserFieldChange(value, 'phone');
                }}
                labelHidden
                prefix={
                  <div className="flex">
                    <span className="mr-[8px] text-black">
                      {countryCodes[editUserForm.timezone] || '+91'}
                    </span>
                    <span className="border-r border-black opacity-30" />
                  </div>
                }
                placeholder="8581234567"
              />
            </span>
          </div>
        </div>
        {editUserErrors.invalidEmailFormat && (
          <span className="text-red-500 text-sm mt-2">Please enter a valid email!</span>
        )}
        {editUserErrors.invalidFieldsError && (
          <span className="block text-red-500 text-sm mt-2">
            Please fill all the required fields.
          </span>
        )}
        {editUserErrors.failed && (
          <span className="block text-red-500 text-sm mt-2">
            * Unable to add User. Please contact{' '}
            <a href="mailto:support@visionify.ai">support@visionify.ai</a>.
          </span>
        )}
      </form>
    </Modal>
  );
};

export default EditUserModal;
