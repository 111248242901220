import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    company: null,
    subscribedScenarios: ['smoke-and-fire-detection','ppe-detection','slip-and-fall-detection', 'min-workers', 'max-workers', 'restricted-area'],
    sitesAvailable: []
};

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCompany: (state, action) => {
      state.company = action.payload;
      if(action?.payload?.license_scenarios)
        state.subscribedScenarios = action.payload.license_scenarios.split(',');
    },
    unsetCompany: (state) => {
      state.company = initialState.company;
      state.subscribedScenarios = initialState.subscribedScenarios;
    },
    setSites: (state, action)  => {
      state.sitesAvailable = action.payload
    }
  }
});

export const {
    setCompany,
    unsetCompany,
    setSites
} = companySlice.actions;

export default companySlice.reducer;
