import { useState, useCallback } from 'react';
import { Button, Card, LegacyCard, ProgressBar, Spinner, Tabs } from '@shopify/polaris';
import React from 'react';
import { PlusMinor } from '@shopify/polaris-icons';
import { Link } from 'react-router-dom';
import campaignnotfound from '../../assets/campaign-not-found.svg';
import usersolid from '../../assets/user-solid.svg';
import calendarsolid from '../../assets/calendar-solid.svg';
import { Icons } from '../../Icons';
import { useQuery } from '@tanstack/react-query';
import { getCampaigns } from '../../api/campaigns.api';

const Campaigns = () => {
  const [selected, setSelected] = useState(0);

  const handleTabChange = useCallback((selectedTabIndex) => setSelected(selectedTabIndex), []);

  const { data: campaigns, isLoading } = useQuery({
    queryKey: ['campaigns', selected],
    queryFn: async () => {
      const response = await getCampaigns({status: selected === 0? 'ALL' : 'UPCOMING'});
      return response.data;
    }
  });

  const [tabs, ] = useState([
    {
      id: 'all',
      content: 'All',
      panelID: 'all-campaign',
      data: []
    },
    {
      id: 'upcoming',
      content: 'Upcoming',
      panelID: 'upcoming-campaign',
      data: []
    }
  ]);


  const renderTabContent = () => {
    return (
      <div className="border-t pt-5 pb-3">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 w-full">
          {campaigns.length ? campaigns.map((campaign, index) => (
            <Link to={`/campaigns/${campaign.id}`} key={index}>
              <div className="box border rounded-[12px] h-full">
                <div className="title bg-[#F7F4FF] rounded-t-[12px] relative">
                  <div className="flex justify-between">
                    <div className="py-3 px-3 flex items-center flex-wrap gap-3">
                      <div className="bg-[#8670C5] rounded-[5px]">
                        <p className="text-white text-sm font-semibold text-center py-1">
                          {campaign.date}
                        </p>
                        <h3 className="bg-[#303030] text-[12px] font-medium text-white px-2 py-1 rounded-b-[5px]">
                          {campaign.monthyear}
                        </h3>
                      </div>
                      <div className="title">
                        <p className="text-sm font-semibold text-[#303030]">{campaign.title}</p>
                        <div className="lg:block hidden">
                          <div className="flex items-center flex-wrap flex-none gap-3">
                            <div>
                              <p className="text-[12px] pt-2 text-[#303030] flex items-center gap-2">
                                <img className="w-[15px]" src={usersolid} />
                                {campaign.organizer}
                              </p>
                            </div>
                            {campaign.duration && (
                              <>
                                <span className="w-1 h-1 mt-2 bg-[#616161] rounded-full"></span>
                                <div>
                                  <p className="text-[12px] pt-2 text-[#303030] flex items-center gap-2">
                                    <img className="w-[15px]" src={calendarsolid} />
                                    {campaign.duration}
                                  </p>
                                </div>
                              </>
                            )}

                            <span className="w-1 h-1 mt-2 bg-[#616161] rounded-full"></span>
                            <div>
                              <p className="text-[12px] pt-2 text-[#303030] flex items-center gap-2">
                                <p className="text-[12px]  text-[#303030]">Events :</p>
                                {campaign.event}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      {campaign.percentage && (
                        <div className="bg-[#8670C5] complete-percentage rounded-bl-[10px] rounded-tr-[10px]">
                          <p className="text-base font-bold py-1 px-1 text-center text-white">
                            {campaign.percentage}
                          </p>
                        </div>
                      )}
                      <div className="flex items-center gap-2 bg-black bg-opacity-10 py-1 px-2 rounded-[8px] mt-3 mr-3">
                        <span
                          className={`w-2 h-2 rounded-full ${
                            campaign.status === 'Active'
                              ? 'bg-green-500'
                              : campaign.status === 'Upcoming'
                                ? 'bg-yellow-500'
                                : 'bg-gray-500'
                          }`}></span>
                        <span className="text-[12px] text-[#303030]">{campaign.status}</span>
                      </div>
                    </div>
                  </div>
                  <div className="block lg:hidden">
                    <div className="px-3 pb-3 flex items-center flex-wrap gap-3">
                      <div>
                        <p className="text-[12px] pt-2 text-[#303030] flex items-center gap-2">
                          <img className="w-[15px]" src={usersolid} />
                          {campaign.organizer}
                        </p>
                      </div>
                      {campaign.duration && (
                        <>
                          <span className="w-1 h-1 mt-2 bg-[#616161] rounded-full"></span>
                          <div>
                            <p className="text-[12px] pt-2 text-[#303030] flex items-center gap-2">
                              <img className="w-[15px]" src={calendarsolid} />
                              {campaign.duration}
                            </p>
                          </div>
                        </>
                      )}

                      <span className="w-1 h-1 mt-2 bg-[#616161] rounded-full"></span>
                      <div>
                        <p className="text-[12px] pt-2 text-[#303030] flex items-center gap-2">
                          <p className="text-[12px] text-[#303030]">Events :</p>
                          {campaign.event}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="topic py-3 px-3 space-y-3">
                  <div className="flex items-center flex-wrap gap-5">
                    {campaign.tasks.map((training, index) => (
                      <div key={index} className="flex items-center gap-2">
                        <Icons.CompleteIco
                          className={`w-4 ${
                            training.status === 'todo'
                              ? 'fill-[#303030]'
                              : training.status === 'ongoing'
                                ? 'fill-[#F7931A]'
                                : 'fill-[#45A636]'
                          }`}
                        />
                        <p
                          className={`font-normal text-sm ${
                            training.status === 'todo'
                              ? 'text-[#303030]'
                              : training.status === 'ongoing'
                                ? 'text-[#F7931A]'
                                : 'text-[#45A636]'
                          }`}>
                          {training.value}
                        </p>
                      </div>
                    ))}
                  </div>
                  <div className="flex items-center justify-between gap-5">
                    <ProgressBar progress={parseInt(campaign.progress)} size="small" tone="primary" />
                    <p className="flex-none">
                      {campaign.daysRemaining} Day{campaign.daysRemaining > 1 ? 's' : ''} Remaining
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          )): (
            <div className="w-full space-y-5 text-center py-20">
            <img src={campaignnotfound} className="mx-auto w-[280px]" />
            <h3 className="font-semibold text-[20px]">No Campaigns Found</h3>
            <p>This section is currently empty. You can add campaigns</p>
            <div>
              <Link to="/add-campaign">
                <Button variant="primary" size="large" icon={PlusMinor} key="Inferencevideo">
                  Add New Campaign
                </Button>
              </Link>
            </div>
          </div>
          )}
        </div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="grid place-items-center h-screen w-screen">
        <Spinner size="large" />
      </div>
    );
  }

  return (
    <>
      <div className="radiousnone main-title">
        <div className="bg-white py-3 px-3 shadow-md border-b flex justify-between">
          <div className="title smallbtn flex items-center gap-3">
            <h3 className="text-black text-lg font-semibold">Campaigns</h3>
          </div>
          <Link to="/add-campaign">
            <Button icon={PlusMinor} key="Inferencevideo">
              Add New Campaign
            </Button>
          </Link>
        </div>
      </div>
      
          <div className="px-4 padding0 mt-4">
            <Card padding={0}>
              <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                <LegacyCard.Section>{renderTabContent()}</LegacyCard.Section>
              </Tabs>
            </Card>
          </div>
       
    </>
  );
};

export default Campaigns;
