import React from 'react';
import { Card, Text, Badge, Icon } from '@shopify/polaris';
import ReactPlayer from 'react-player';
import Calendar from '../../assets/calendar.svg';
import { useSelector } from 'react-redux';
import { dateToWordsTz } from '../../utils';
import { ClockMajor } from '@shopify/polaris-icons';

const RecordingCard = ({ recording }) => {
  /* eslint-disable no-unused-vars */
  const { id, started_at, stopped_at, status, recording_url, duration } = recording;
  const { user } = useSelector((state) => state.auth);

  // Determine display status based on the presence of recording URL
  const displayStatus =
    status === 'stopped' && recording_url
      ? 'completed'
      : status === 'stopped' && !recording_url
        ? 'in_progress'
        : status;

  // Format the duration in hh:mm:ss if it exists, otherwise show "-"
  const formatDuration = (duration) => {
    if (!duration || displayStatus === 'started' || displayStatus === 'in_progress') return '-';

    const totalSeconds = Math.floor(duration / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const getStatusBadge = (displayStatus) => {
    switch (displayStatus) {
      case 'completed':
        return <Badge tone="success">Completed</Badge>;
      case 'in_progress':
        return <Badge tone="attention">In Progress</Badge>;
      case 'started':
        return <Badge tone="attention">Started</Badge>;
      case 'stopped':
        return <Badge tone="attention">Stopped</Badge>;
      default:
        return <Badge tone="critical">Failed</Badge>;
    }
  };

  return (
    <div className="cursor-pointer hover:opacity-75">
      <Card>
        {displayStatus === 'completed' && recording_url ? (
          <ReactPlayer url={recording_url} controls width="100%" height="200px" />
        ) : (
          <div className="flex items-center justify-center h-48 mt-2 bg-gray-200">
            <Text variant="bodyMd" as="p" color="subdued">
              {displayStatus === 'in_progress' ? 'Recording in progress' : 'No recording available'}
            </Text>
          </div>
        )}

        <div className="p-4">
          <div className="flex justify-between items-center">
            <Text as="p" variant="headingMd" fontWeight="semibold">
              Recording ID: {id}
            </Text>
            {getStatusBadge(displayStatus)}
          </div>

          <div className="flex justify-between items-center mt-4">
            <div className="flex items-center gap-1">
              <img src={Calendar} alt="Calendar Icon" className="w-4 h-4" />
              <Text as="p" variant="bodySm" color="subdued">
                {dateToWordsTz(started_at, user.timezone)}
              </Text>
            </div>

            <div className="flex items-center gap-1">
              <Icon source={ClockMajor} tone="base" />
              <Text as="p" variant="bodySm" color="subdued">
                {formatDuration(duration)}
              </Text>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default RecordingCard;
