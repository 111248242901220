import { useSelector } from "react-redux";

export const Avatar = ({ name, className='w-[100px] h-[100px]', textSize='text-5xl' }) => {
  const {gradient} = useSelector(state => state.app.appearance)
  return (
    <div
      id="avatar"
      className={`avatar ${className} flex items-center justify-center rounded-full text-lg border-white border-4  bg-gradient-to-t ${gradient}`}>
      <span className={`text-black ${textSize}`}>{name?.charAt(0).toUpperCase() ?? 'A'}</span>
    </div>
  );
};



export const SquareAvatar = ({ name }) => {
  const {gradient} = useSelector(state => state.app.appearance)
  return (
    <div
      id="avatar"
      className={`avatar w-[28px] h-[28px] flex items-center justify-center rounded-md text-lg border-white border-2  bg-gradient-to-t ${gradient}`}>
      <span className={`text-black text-md`}>{name?.charAt(0).toUpperCase() ?? 'A'}</span>
    </div>
  );
};
