import React, { useState } from 'react';

const MultiSwitch = ({
  options,
  selectedOption,
  bgColors,
  textColors,
  onSelect,
  fullWidth = true
}) => {
  const [selected, setSelected] = useState(selectedOption);

  return (
    <div className={`relative ${fullWidth ? 'w-64' : 'w-32'}`}>
      <div className="flex justify-between border rounded-sm">
        {options.map((option, index) => (
          <div
            key={index}
            className={`cursor-pointer px-2 py-1 z-10 flex-1 text-center transition-colors duration-300 ${selected === index ? textColors[selected] : 'text-gray-600'}`}
            onClick={() => {
              setSelected(index);
              onSelect(options[index]);
            }}>
            {option}
          </div>
        ))}
      </div>
      <div
        className={`absolute top-0 left-0 h-full ${bgColors[selected]} rounded-sm transition-all duration-300`}
        style={{ width: `${100 / options.length}%`, transform: `translateX(${selected * 100}%)` }}
      />
    </div>
  );
};

export default MultiSwitch;
