import { api } from './index';

const resource = "/company"

export const getCompanyById = async (id) => {
    try {
        if(!id) return {
            success: false,
            message: "Company id is required"
        }
        const response = await api.get(`${resource}/${id}`)
        return response.data
    } catch (e) {
        throw new Error(e)
    }
}

export const getMyCompany = async () => {
    try {
        const response = await api.get(`${resource}/me`)
        return response.data
    }
    catch (e) {
        throw new Error(e)
    }
}

export const updateMyCompany = async (data) => {
    try {
        const response = await api.post(`${resource}/me`, data)
        return response.data
    } catch (error) {
        throw new Error(error)
    }
}