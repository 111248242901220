import { Button, Card } from '@shopify/polaris';
import area1 from '../../assets/area1.png';
import area2 from '../../assets/area2.png';
import area3 from '../../assets/area3.png';
import area4 from '../../assets/area4.png';
import { Icons } from '../../Icons';
import viewCamera from '../../assets/view-camera.svg';
import { Link } from 'react-router-dom';
import { PlusMinor } from '@shopify/polaris-icons';

const Areas = () => {
  const areasData = [
    {
      title: 'Wing Assembly Area',
      images: [area1, area2, area3, area4],
      cameraCount: 0,
      link: '/areas-detail/1'
    },
    {
      title: 'Engine Maintenance Bay',
      images: [area1, area2],
      cameraCount: 2,
      link: '/areas-detail/2'
    },
    {
      title: 'Landing Gear Inspection Area',
      images: [area1, area2, area3],
      cameraCount: 4,
      link: '/areas-detail/3'
    }
  ];

  return (
    <>
      <div className="radiousnone main-title">
        <div className="bg-white py-3 px-3 shadow-md border-b">
          <div className="title flex items-center justify-between smallbtn">
            <div className="flex items-center gap-3">
              <div className="title">
                <h3 className="text-lg font-semibold">Areas</h3>
              </div>
            </div>
            <Button largesize="large" variant="primary" icon={PlusMinor} onClick={() => {}}>
              Add Area
            </Button>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 py-5 px-5 cardheight">
        {areasData.map((area, index) => (
          <Card key={index} padding={0}>
            <div className="areas py-5 px-5 flex flex-col h-full">
              <div
                className={`grid ${area.images.length === 1 ? 'sm:grid-cols-1' : 'sm:grid-cols-2'} gap-3 xl:gap-5 max-h-[365px] overflow-auto mb-3`}>
                {area.images.map((image, imgIndex) => (
                  <img
                    key={imgIndex}
                    className="w-full h-auto"
                    src={image}
                    alt={`area ${index + 1}`}
                  />
                ))}
              </div>
              <div className="flex items-center justify-between gap-3 mt-auto">
                <div>
                  <h3 className="text-base font-semibold text-black">{area.title}</h3>
                  <div className="flex items-center gap-2 mt-2">
                    <Icons.CameraIco className="w-6 h-6" fill="#303030" />
                    <p className="text-sm">{area.cameraCount} Cameras</p>
                  </div>
                </div>
                <Link to={area.link}>
                  <img src={viewCamera} className="w-[30px] cursor-pointer" />
                </Link>
              </div>
            </div>
          </Card>
        ))}
      </div>
    </>
  );
};

export default Areas;
