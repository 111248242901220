import { Pagination, Spinner } from '@shopify/polaris';
import CameraB64 from '../Camera-B64';
import { deepEqual } from '../../utils';
import { PAGE_COUNT } from '../../containers/ApplyScenarios';
import { Link } from 'react-router-dom';

const Step1 = ({
  cameraId,
  edit,
  formData,
  setFormData,
  cameraLoading,
  paginationData,
  setPaginationData,
  cameraData
}) => {
  const addOrRemoveCamera = (cam, action = null) => {
    if (action === 'ADD') {
      setFormData({ ...formData, cameras: [...new Set([...formData.cameras, cam])] });
      return;
    }
    if (action === 'REMOVE') {
      setFormData({ ...formData, cameras: formData.cameras.filter((c) => !deepEqual(c, cam)) });
      return;
    }
    if (formData.cameras.some((c) => deepEqual(c, cam))) {
      setFormData({
        ...formData,
        cameras: formData.cameras.filter((c) => !deepEqual(c, cam))
      });
    } else {
      setFormData({ ...formData, cameras: [...new Set([...formData.cameras, cam]) ]});
    }
  };
  return (
    <div className="flex flex-col justify-between mx-6">
      <div className="mt-16">
        {cameraLoading && <Spinner accessibilityLabel="Loading" size="large" color="teal" />}
        {paginationData.cameras.length === 0 && !cameraLoading ? (
          <div className="h-32 grid place-items-center">
            <p>This scenario has been applied to all cameras!</p>
            <Link to="/scenarios" className="text-[#242424] underline underline-offset-2">
              <div className="flex items-center">
                <p>Go back to Scenarios {'>'}</p>
              </div>
            </Link>
          </div>
        ) : (
          <div className="flex-1">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
              {paginationData.cameras
                .filter((c) => {
                  if (!edit) return true;
                  if (edit) {
                    if (c.id === Number(cameraId)) return true;
                  }
                  return false;
                })
                .map((camera) => (
                  <CameraB64
                  edit={edit}
                    addOrRemoveCamera={addOrRemoveCamera}
                    chosenCameras={formData.cameras}
                    key={camera.id}
                    camera={camera}
                  />
                ))}
            </div>
          </div>
          // </div>
        )}
      </div>
      {!edit && paginationData.cameras.length > 0 && !cameraLoading && (
        <div className="flex justify-center mt-4">
          <Pagination
            label={`Showing Cameras for Page ${paginationData.currentPage} of ${Math.ceil(cameraData?.length / PAGE_COUNT)}`}
            hasPrevious={paginationData.hasPrevious}
            onPrevious={() => {
              setPaginationData(() => {
                return {
                  cameras: cameraData?.slice(
                    (paginationData.currentPage - 2) * PAGE_COUNT,
                    (paginationData.currentPage - 1) * PAGE_COUNT
                  ),
                  currentPage: paginationData.currentPage - 1,
                  hasNext: true,
                  hasPrevious: paginationData.currentPage - 1 > 1
                };
              });
            }}
            hasNext={paginationData.hasNext}
            onNext={() => {
              setPaginationData(() => {
                return {
                  cameras: cameraData?.slice(
                    paginationData.currentPage * PAGE_COUNT,
                    (paginationData.currentPage + 1) * PAGE_COUNT
                  ),
                  currentPage: paginationData.currentPage + 1,
                  hasNext: (paginationData.currentPage + 1) * PAGE_COUNT < cameraData?.length,
                  hasPrevious: true
                };
              });
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Step1;
