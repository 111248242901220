/* eslint-disable no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import EventFilter from './EventFilter';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEventsByDate, getAllEvents } from '../../api/events.api';
import { formatDate } from '../../utils';
import { useEffect, useState } from 'react';
import { Pagination, Spinner } from '@shopify/polaris';
import EventsPerDate from './EventsPerDate';
import EventOverlay from '../../components/EventOverlay';
import {
  resetEventSlice,
  setDoesDateHaveMore,
  setEvents,
  setTimezone
} from '../../store/slices/eventSlice';

const processEventsObject = (eventsObj) => {
  const result = [];
  Object.keys(eventsObj).forEach((date) => {
    eventsObj[date].forEach((event) => {
      result.push(event);
    });
  });

  return result ?? [];
};

const Events = () => {
  const {
    selectedCamera,
    selectedTag,
    selectedScenarios,
    selectedDates,
    chosenEventIdx,
    selectedScenarioEvents,
    chosenDateIdx,
    selectedReviewType
  } = useSelector((state) => state.event);


  const dispatch = useDispatch();

  const [page, setPage] = useState(1);

  const {
    data: eventData,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: [
      'events',
      selectedCamera,
      selectedTag,
      selectedScenarios,
      selectedDates,
      selectedScenarioEvents,
      selectedReviewType,
      page
    ],
    queryFn: () =>
      fetchEventsByDate({
        reviewType: selectedReviewType,
        scenarios: selectedScenarios,
        eventNames: selectedScenarioEvents,
        camera: selectedCamera,
        tag: selectedTag,
        startDate: formatDate(selectedDates.start),
        endDate: formatDate(selectedDates.end),
        page
      }),
  });


  useEffect(() => {
    if (!isLoading && !isError && eventData) {
      dispatch(setTimezone(eventData.timezone));
      const eventsArr = processEventsObject(eventData.data);
      dispatch(setEvents(eventsArr));
      dispatch(setDoesDateHaveMore(eventData.dateViewMoreBoolean));
    }
  }, [eventData]);

  useEffect(() => {
    return () => {
      dispatch(resetEventSlice());
    };
  }, [dispatch]);
  return (
    <>
      {chosenEventIdx ? (
        <EventOverlay refetch={refetch} />
      ) : (
        <>
          <EventFilter />
          {isLoading ? (
            <div className="h-screen grid place-items-center">
              <Spinner size="large" />
            </div>
          ) : isError ? (
            <div className="h-full grid place-items-center text-center px-4">
              <img
                src="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
                className="empty-state w-[250px]"
              />
              <h3 className="font-semibold text-lg pb-4">Unable to fetch events</h3>
              <p>
                We were unable to fetch events. Please try again later or contact support if issue
                persists.
              </p>
            </div>
          ) : (
            <div className="px-4 py-4">
              {eventData?.data && Object.keys(eventData.data).length > 0 ? (
                <div>
                  {Object.keys(eventData.data).map((date, idx) => {
                    return (
                      <EventsPerDate
                        key={idx}
                        date={date}
                        events={eventData.data[date]}
                        currentIdx={idx}
                      />
                    );
                  })}
                  {chosenDateIdx === null && (
                    <div className="flex justify-center bg-white rounded-md shadow-xl py-3">
                      <Pagination
                        onPrevious={() => {
                          setPage((prev) => prev - 1);
                        }}
                        onNext={() => {
                          setPage((prev) => prev + 1);
                        }}
                        hasNext={eventData.hasMore}
                        hasPrevious={page > 1}
                        label={`Page ${page}`}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div className="h-full grid place-items-center text-center px-4">
                  <img
                    src="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
                    className="empty-state w-[250px]"
                  />
                  <h3 className="font-semibold text-lg pb-4">Unable to fetch events</h3>
                  <p>
                    We were unable to fetch events. Please try again later or contact support if
                    issue persists.
                  </p>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Events;
