import { Button, Card, DataTable, Icon } from '@shopify/polaris';
import TableHeading from '../../components/TableHeading';
import { SettingsFilledMinor } from '@shopify/polaris-icons';
import { useState } from 'react';
import UsersListModal from '../../components/UsersListModal';
import { useMutation } from '@tanstack/react-query';
import {
  editNotificationUsersList,
  getNotificationUserGroupsForNotificationGroup
} from '../../api/notification-groups.api';
import EditNotificationGroupUser from '../../components/EditNotificationGroupUser';
import { notification } from 'antd';

const NotificationGroups = ({ data, error, users }) => {

  const [api, contextHolder] = notification.useNotification();

  const [openEditModal, setOpenEditModal] = useState(false);
  const [editData, setEditData] = useState({
    groupInfo: null,
    groupName: '',
    emailList: [],
    emailInputValue: '',
    permissionEnabled: true
  });
  const [editErrors, setEditErrors] = useState({
    invalidFieldsError: false,
    failed: false
  });
  const [showUsersModal, setShowUsersModal] = useState(false);
  const [userData, setUserData] = useState([]);

  const { mutate, isPending, isError } = useMutation({
    mutationFn: (groupId) => getNotificationUserGroupsForNotificationGroup(groupId),
    onSuccess: (data) => {
      setUserData(data);
      setEditData({ ...editData, emailList: data.map((user) => user.user.email) });
    }
  });

  const { mutate: editUserGroup, isPending: isEditUserPending, isError: isUserGroupError } = useMutation({
    mutationKey: ['editUserGroup'],
    mutationFn: (data) => {
      return editNotificationUsersList(data);
    },
    onSuccess: () => {
      setOpenEditModal(false);
      api.success({
        message: 'Notification group updated successfully!'
      });
    },
    onError: () => {
      setEditErrors({ ...editErrors, failed: true });
      api.error({
        message: "Couldn't update notification group! Please try again."
      });
    }
  });

  const handleNotificationGroupOk = (group, emailList) => {
    editUserGroup({ notificationId: group.id, emailList, usersList: users });
  };
  const constructRows = (data, showModal) => {
    if (!data) return [];
    const rows = data.map((group) => {
      return [
        group.name,
        <Button
          key={`show-users-button-${group.id}`}
          variant="primary"
          onClick={() => {
            mutate(group.id);
            showModal(true);
          }}>
          Show Users
        </Button>,
        group.status ? 'Yes' : 'No',
        <Button
          key={`button-${group.id}`}
          onClick={() => {
            setOpenEditModal(true);
            mutate(group.id);
            setEditData({
              groupInfo: group,
              groupName: group.name,
              emailList: [],
              emailInputValue: '',
              permissionEnabled: true
            });
          }}>
          <Icon source={SettingsFilledMinor} />
        </Button>
      ];
    });
    return rows ?? [];
  };
  if (error) {
    return (
      <Card>
        Something went wrong! Please try again later or contact{' '}
        <a
          className="text-blue-500 hover:text-blue-900 cursor-pointer underline underline-offset-2"
          href="mailto:support@visionify.ai">
          support@visionify.ai
        </a>
      </Card>
    );
  }
  return (
    <>
      {contextHolder}
      <Card sectioned>
        <DataTable
          columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text']}
          headings={[
            <TableHeading key={1} text="Group" />,
            <TableHeading key={2} text="Name" />,
            <TableHeading key={3} text="Enabled" />,
            <TableHeading key={4} text="Actions" />
          ]}
          hasZebraStripingOnData
          rows={constructRows(data, setShowUsersModal)}
        />
      </Card>
      <UsersListModal
        open={showUsersModal}
        handleCancel={() => setShowUsersModal(false)}
        isLoading={isPending}
        isError={isError}
        data={userData}
      />

      <EditNotificationGroupUser
        form={editData}
        setForm={setEditData}
        isPending={isEditUserPending}
        isLoading={isPending}
        isError={isUserGroupError}
        errors={editErrors}
        open={openEditModal}
        handleOk={handleNotificationGroupOk}
        handleCancel={() => {
          setOpenEditModal(false);
          setEditData({
            groupName: '',
            emailList: [],
            emailInputValue: '',
            permissionEnabled: true,
            groupInfo: null
          });
          setEditErrors({ invalidFieldsError: false, failed: false });
          
        }}
      />
    </>
  );
};
export default NotificationGroups;
