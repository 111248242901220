import React from 'react';
import { Button, Spinner, TextField, Tag } from '@shopify/polaris';
import { Modal } from 'antd';

const EmailInputWithBadges = ({ form, setForm }) => {
  const validateEmail = (email) => {
    var regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleInputChange = (event) => {
    const newEmailValue = event.target.value;
    setForm({ ...form, emailInputValue: newEmailValue });

    if (validateEmail(newEmailValue)) {
      const domain = newEmailValue.split('@')[1];
      if (
        domain.endsWith('.ai') ||
        domain.endsWith('.com') ||
        domain.endsWith('.in') ||
        domain.endsWith('.io') ||
        domain.endsWith('.net') ||
        domain.endsWith('.me') ||
        domain.endsWith('.app') ||
        domain.endsWith('.org')
      ) {
        if (!form.emailList.includes(newEmailValue.trim())) {
          addEmail(newEmailValue);
        }
      }
    }
  };

  const addEmail = (email) => {
    setForm({
      ...form,
      emailList: [...form.emailList, email.trim()],
      emailInputValue: ''
    });
  };

  const handleInputKeyDown = (event) => {
    if (
      (event.key === 'Enter' || event.key === ' ') &&
      validateEmail(form.emailInputValue) &&
      form.emailList.includes(form.emailInputValue.trim()) === false
    ) {
      setForm({
        ...form,
        emailList: [...form.emailList, form.emailInputValue.trim()],
        emailInputValue: ''
      });
      event.preventDefault();
    }
  };

  const removeEmail = (emailToRemove) => {
    setForm({ ...form, emailList: form.emailList.filter((email) => email !== emailToRemove) });
  };

  return (
    <div className="border rounded-md py-1 px-2 border-[#8A8A8A]">
      {form.emailList &&
        form.emailList.map((email, index) => (
          <span className="mr-2" key={index}>
            <Tag onRemove={() => removeEmail(email)}>{email}</Tag>
          </span>
        ))}
      <input
        className="flex-1 w-full h-full outline-none"
        type="text"
        value={form.emailInputValue}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        placeholder="Type an email and press Enter"
      />
    </div>
  );
};

const AddNotificationGroupUser = ({
  open,
  handleOk,
  handleCancel,
  isPending,
  form,
  setForm,
  errors
}) => {
  return (
    <Modal
      title="Notification Group"
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{ disabled: true }}
      cancelButtonProps={{ disabled: true }}
      maskClosable={false}
      footer={[
        <div className="inline mr-2" key="back">
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
        </div>,
        <div className="inline mr-2" key="submit">
          <Button variant="primary" onClick={handleOk}>
            <div className="flex items-center">
              Submit
              {isPending && (
                <span className="ml-2">
                  <Spinner size="small" />
                </span>
              )}
            </div>
          </Button>
        </div>
      ]}>
      <form>
        <div className="w-full">
          <div className="flex items-center mb-2">
            <span className="flex-1">
              Group Name<span className="text-red-500">*</span>
            </span>

            <span className="w-1/2 lg:w-3/4">
              <TextField
                value={form.groupName}
                onChange={(value) => setForm({ ...form, groupName: value })}
                labelHidden
                placeholder="Visionify's Admin Group"
              />
            </span>
          </div>
          <div className="flex items-center mb-4">
            <span className="flex-1">
              Email Addresses<span className="text-red-500">*</span>
            </span>

            <span className="w-1/2 lg:w-3/4">
              <EmailInputWithBadges form={form} setForm={setForm} />
            </span>
          </div>
          {/* <div className="flex items-center mb-2">
            <span className="flex-1">
              Permissions<span className="text-red-500">*</span>
            </span>

            <span className="w-1/2 lg:w-3/4">
              <Checkbox
                label="Enabled"
                checked={form.permissionsEnabled}
                onChange={() => setForm({ ...form, permissionsEnabled: !form.permissionsEnabled })}
              />
            </span>
          </div> */}
          {errors.invalidFieldsError && (
            <span className="block text-red-500 text-sm mt-2">
              Please fill all the required fields.
            </span>
          )}
          {errors.failed && (
            <span className="block text-red-500 text-sm mt-2">
              * Unable to add User. Please contact{' '}
              <a href="mailto:support@visionify.ai">support@visionify.ai</a>.
            </span>
          )}
        </div>
      </form>
    </Modal>
  );
};

export default AddNotificationGroupUser;
