import { Spinner } from '@shopify/polaris';
import { Check, XCircle } from 'lucide-react';
import { convertKeyToTitle } from '../../utils';

const Step4 = ({ formData, isApplying, scenarioData, isError }) => {
  return (
    <div className="my-20 flex flex-col justify-between">
      <div className="flex flex-col items-center justify-center">
        <div className="">
          {isApplying ? (
            <Spinner size="large" />
          ) : isError ? (
            <XCircle color="#ca0707" size={80} />
          ) : (
            <Check size={80} color="#38ab07" />
          )}
        </div>
        <h2 className="text-center mt-4 font-semibold text-[18px]">
          {isApplying
            ? 'Applying Scenario'
            : isError
              ? 'Failed to apply scenario to camera(s)'
              : 'Scenario Applied Successfully'}
        </h2>

        {!isError && (
          <div>
            <p className="text-center mt-2 text-[13px] text-[#444444]">
              Please find the information pertaining to the scenario that has been applied below.
            </p>
            <div className="mt-4 py-6 px-4 w-full border flex bg-[#F7F7F7]">
              <div className="flex-1 text-right mr-2">
                <p className="text-sm font-light">Scenario Name</p>
                <p className="text-md font-semibold">{scenarioData.title}</p>
              </div>
              <div className="flex-1 w-full ml-2">
                <p className="text-sm font-light">Applied for Cameras</p>
                <p className="text-md font-semibold">
                  {formData.cameras.map((camera) => (
                    <p className="font-medium" key={camera.id}>
                      {convertKeyToTitle(camera.name, '_')}
                    </p>
                  ))}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Step4;
