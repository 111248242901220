import { Button, Divider, Icon } from '@shopify/polaris';
import EventCard from './EventCard';
import Spacer from '../../components/Spacer';
import { useDispatch, useSelector } from 'react-redux';
import { setChosenDateEvents, setChosenDateIdx } from '../../store/slices/eventSlice';
import { useMutation } from '@tanstack/react-query';
import { fetchEventsByDate } from '../../api/events.api';
import { formatDate } from '../../utils';
import { notification } from 'antd';
import { ChevronLeftMinor } from '@shopify/polaris-icons';

const EventsPerDate = ({ events, date, currentIdx }) => {
  const [api, contextHolder] = notification.useNotification();

  const dispatch = useDispatch();
  const {
    selectedCamera,
    selectedTag,
    selectedDates,
    selectedScenarioEvents,
    chosenDateIdx,
    doesDateHaveMore,
    chosenDateEvents,
    selectedReviewType
  } = useSelector((state) => state.event);

  const onViewMore = () => {
    if (chosenDateIdx !== null) {
      dispatch(setChosenDateIdx(null));
      dispatch(setChosenDateEvents([]));
    } else {
      dispatch(setChosenDateIdx(currentIdx));
      mutate();
    }
  };

  const { mutate } = useMutation({
    mutationFn: () =>
      fetchEventsByDate({
        eventNames: selectedScenarioEvents,
        camera: selectedCamera,
        tag: selectedTag,
        startDate: formatDate(selectedDates.start),
        endDate: formatDate(selectedDates.end),
        specificDate: date,
        reviewType: selectedReviewType
      }),
    onSuccess: (data) => {
      dispatch(setChosenDateEvents(data.data));
      api.success({ message: 'Events fetched successfully. Please scroll down!' });
    },
    onError: () => {
      dispatch(setChosenDateIdx(null));
      dispatch(setChosenDateEvents([]));
      api.error({ message: 'Unable to fetch events. Please try again later!' });
    }
  });

  return (
    <div
      className={`my-4 bg-white rounded-md p-4 shadow-md ${chosenDateIdx !== null && currentIdx !== chosenDateIdx && 'hide'}`}>
      {contextHolder}
      <div className="flex items-center justify-between">
        <div className="flex">
          {chosenDateIdx !== null && (
            <span className="mr-4">
              <Button onClick={onViewMore} variant="plain">
                <div className="flex items-center">
                  <Icon source={ChevronLeftMinor} />{' '}
                  <span className="ml-1">Go Back to All Events</span>
                </div>
              </Button>
            </span>
          )}
          <h3 className="font-semibold">{date}</h3>
        </div>

        {doesDateHaveMore && doesDateHaveMore[date] && chosenDateIdx === null && (
          <Button onClick={onViewMore} variant="secondary">
            View More
          </Button>
        )}
      </div>
      <Spacer height={'10px'} />
      <Divider />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-5 pt-4">
        {chosenDateIdx !== null
          ? chosenDateEvents.map((event, idx) => {
              return <EventCard key={idx} event={event} />;
            })
          : events.map((event, idx) => {
              return <EventCard key={idx} event={event} />;
            })}
      </div>
    </div>
  );
};

export default EventsPerDate;
