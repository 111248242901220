import { Button, EmptyState, Spinner } from '@shopify/polaris';
import { Modal } from 'antd';

const GraphicDeleteModal = ({
  open,
  title = 'Delete User',
  text = 'Are you sure you want to delete this user?',
  handleOk,
  handleCancel,
  isPending
}) => {
  return (
    <Modal
      title={title}
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <div className="inline mr-2" key="back">
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
        </div>,
        <div className="inline mr-2" key="submit">
          <Button variant="primary" onClick={handleOk}>
            <div className="flex items-center">
              Submit
              {isPending && (
                <span className="ml-2">
                  <Spinner size="small" />
                </span>
              )}
            </div>
          </Button>
        </div>
      ]}>
      <EmptyState heading="Are you sure?" image="https://res.cloudinary.com/dznb3fphf/image/upload/v1711011319/visionai-analysis/trash-can-img.svg">
        <p>{text}</p>
      </EmptyState>
    </Modal>
  );
};

export default GraphicDeleteModal;
