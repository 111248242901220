import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
const PublicRoutes = () => {
    const auth = useSelector(state => state.auth)


    
    return (
        !auth.user ? <Outlet /> : <Navigate to='/events' />
    )
}

export default PublicRoutes
