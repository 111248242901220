/* eslint-disable */
import { Card } from '@shopify/polaris';
import React, { useEffect } from 'react';
import BarChart from '../../components/charts/Bar';


const BarGraph = ({isLoading, data }) => {
  return (
    <>
      <div className="mb-4">
        <Card>
          <div className="flex justify-between flex-wrap items-center pb-4 border-b lg:space-y-0 space-y-5">
            <h3 className="font-semibold text-[20px]">Event Overview</h3>
          </div>
          <div className="statistics pt-4 grid grid-cols-1 md:grid-cols-1 xl:grid-cols-4 gap-5">
            <div className="xl:col-span-4">
              <div>
                <BarChart isLoading={isLoading} data={data} />
              </div>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default BarGraph;
