import { Spinner } from '@shopify/polaris';
import React, { useEffect, useState, useRef } from 'react';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, Title, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, Title, Legend);

function BarChart({ isLoading, data, setSeverityTypeFilter }) {
  const chartRef = useRef(null);

  const createGradient = (ctx) => {
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, 'rgba(225, 202, 255, 1)');
    gradient.addColorStop(1, 'rgba(156, 106, 222, 1)');
    return gradient;
  };

  const barchartoptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltips: {
        enabled: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
          borderDash: [3, 3], // Make Y axis grid lines dotted
          borderDashOffset: 2
        }
      },
      y: {
        grid: {
          color: 'rgba(0, 0, 0, 0.1)', // Color of Y axis grid lines
          borderDash: [3, 3], // Make Y axis grid lines dotted
          borderDashOffset: 2
        },
        ticks: {
          stepSize: 30 // Adjust this value to control grid line spacing
        }
      }
    }
  };

  const onClick = (event) => {
    const element = getElementAtEvent(chartRef.current, event);
    if (!element.length) {
      setSeverityTypeFilter(null);
      return;
    }
    if (element[0]?.index === 0) {
      setSeverityTypeFilter('Low');
    } else if (element[0]?.index === 1) {
      setSeverityTypeFilter('Moderate');
    } else if (element[0]?.index === 2) {
      setSeverityTypeFilter('High');
    }
  };

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: '',
        data: [],
        hoverOffset: 2,
        maxBarThickness: 40,
        borderRadius: 8,
        backgroundColor: ''
      }
    ]
  });

  useEffect(() => {
    if (chartRef.current && data) {
      const chart = chartRef.current;
      const ctx = chart.ctx;
      const gradient = createGradient(ctx);

      setChartData({
        labels: data.labels ?? [],
        datasets: [
          {
            label: '',
            data: data?.values ?? [],
            hoverOffset: 2,
            maxBarThickness: 40,
            borderRadius: 8,
            backgroundColor: gradient
          }
        ]
      });
    }
  }, [chartRef, data]);

  return (
    <div className="border rounded-md p-2 h-full">
      {isLoading ? (
        <div className="flex justify-center items-center" style={{ height: '400px' }}>
          <Spinner accessibilityLabel="Loading chart" size="large" />
        </div>
      ) : (
        <div>
          <div className="flex items-center justify-between">
            <h3 className="font-semibold text-[18px] pb-3 pt-2 pl-2">Severity Breakdown</h3>
          </div>
          <div className="bar-chart-container mx-[10px] py-5 pb-0" style={{ height: '300px' }}>
            <Bar onClick={onClick} ref={chartRef} data={chartData} options={barchartoptions} />
          </div>
        </div>
      )}
    </div>
  );
}

export default BarChart;
