import { Card, Tracker } from '@tremor/react';
import { RiCheckboxCircleFill, RiCloseCircleFill } from '@remixicon/react';
import { dateToWordsTz } from '../../utils';
import { useSelector } from 'react-redux';

export const calculateStartEndTime = (timeRange) => {
  const endTime = new Date();
  let startTime = new Date();

  switch (timeRange) {
    case 'LAST_24_HOURS':
      startTime.setDate(endTime.getDate() - 1);
      break;
    case 'LAST_7_DAYS':
      startTime.setDate(endTime.getDate() - 7);
      break;
    case 'LAST_30_DAYS':
      startTime.setDate(endTime.getDate() - 30);
      break;
    default:
      break;
  }

  return { startTime, endTime };
};

export const formatCameraDataForTracker = (cameraData, timeRange) => {
  const { user } = useSelector((state) => state.auth);
  const colorMapping = {
    online: 'emerald-500',
    offline: 'red-500'
  };

  const { startTime, endTime } = calculateStartEndTime(timeRange);

  // Format the transitions into data usable by the Tracker component
  const formattedData = [];

  cameraData.forEach((camera) => {
    const { transitions } = camera;

    transitions.forEach((transition) => {
      const transitionStart = new Date(transition.start_time);
      const transitionEnd = transition.end_time === 'now' ? endTime : new Date(transition.end_time);

      // If the transition is outside the time range, skip it
      if (transitionEnd <= startTime || transitionStart >= endTime) {
        return;
      }

      // Clip the transition times to fit within the time range
      const clippedStartTime = transitionStart < startTime ? startTime : transitionStart;
      const clippedEndTime = transitionEnd > endTime ? endTime : transitionEnd;

      let currentTime = new Date(clippedStartTime);
      while (currentTime < clippedEndTime) {
        formattedData.push({
          tooltip: dateToWordsTz(currentTime, user?.timezone ?? 'UTC'),
          status: transition.status,
          color: colorMapping[transition.status]
        });
        currentTime.setHours(currentTime.getHours() + 1);
      }
    });
  });

  return formattedData;
};

const CameraStatusTracker = ({ cameraStatusLogs, timeRange }) => {
  if (!cameraStatusLogs || cameraStatusLogs.length === 0) {
    return null;
  }

  const trackerData = formatCameraDataForTracker(cameraStatusLogs, timeRange);

  const camera = cameraStatusLogs[0];
  const { cameraName, currentStatus, summary } = camera;

  const { startTime } = calculateStartEndTime(timeRange);

  const isOperational = currentStatus === 'online';
  const statusColor = isOperational ? 'bg-emerald-500' : 'bg-red-500';
  const statusText = isOperational ? 'Online' : 'Offline';

  return (
    <>
      <Card>
        <div className="flex items-center justify-between">
          <h3 className="font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
            {cameraName} Status Timeline
          </h3>
          <span
            tabIndex="1"
            className={`inline-flex items-center gap-2 rounded-tremor-full px-3 py-1 text-tremor-default text-tremor-content-emphasis ring-1 ring-inset ring-tremor-ring dark:text-dark-tremor-content-emphasis dark:ring-dark-tremor-ring`}
          >
            <span
              className={`-ml-0.5 size-2 rounded-tremor-full ${statusColor}`}
              aria-hidden={true}
            />
            {statusText}
          </span>
        </div>
        <div className="mt-8 flex items-center justify-between">
          <div className="flex items-center space-x-2">
            {isOperational ? (
              <RiCheckboxCircleFill
                className="size-5 shrink-0 text-emerald-500"
                aria-hidden={true}
              />
            ) : (
              <RiCloseCircleFill className="size-5 shrink-0 text-red-500" aria-hidden={true} />
            )}

            <p className="text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
              Status
            </p>
          </div>
          <p className="text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
            {`${summary.uptimePercentage}% uptime`}
          </p>
        </div>
        <Tracker data={trackerData} className="mt-4 w-full flex" />
        <div className="mt-3 flex items-center justify-between text-tremor-default text-tremor-content dark:text-dark-tremor-content">
          <span>{startTime.toLocaleDateString()}</span>
          <span>Today</span>
        </div>
      </Card>
    </>
  );
};

export default CameraStatusTracker;
