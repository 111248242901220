import { api } from './index';
const resource = '/integrations';

export const getIntegrations = async () => {
  try {
    const response = await api.get(`${resource}`);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getIntegrationById = async (id) => {
  try {
    const response = await api.get(`${resource}/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const createIntegration = async (data) => {
  try {
    const response = await api.post(`${resource}`, data);
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    const errorStatus = error.response?.status;
    throw { message: errorMessage, status: errorStatus };
  }
};

export const updateIntegration = async ({ id, data }) => {
  try {
    const response = await api.put(`${resource}/${id}`, data);
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    const errorStatus = error.response?.status;
    throw { message: errorMessage, status: errorStatus };
  }
};

export const deleteIntegration = async (id) => {
  try {
    const response = await api.delete(`${resource}/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};
