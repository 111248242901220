import { api } from './index';
const resource = '/camera-status-logs';

export const getCameraStatusLogs = async (filters) => {
  try {
    const queryString = Object.keys(filters)
      .map((key) => `${key}=${filters[key]}`)
      .join('&');
    const response = await api.get(`${resource}${queryString ? `?${queryString}` : ''}`);
    return response.data;
  } catch (error) {
    console.log({ error });
    throw { message: error.message, status: error.status };
  }
};
