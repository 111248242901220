import React from 'react';
import '../styles/Switch.css';

const Switch = (props) => {
  return (
    <>
      <input
        checked={props.isOn}
        onChange={props.handleToggle}
        type="checkbox"
        // id={'react-switch-new'}
        id={props.id}
        className="react-switch-checkbox"
      />
      <label
        style={{ background: props.isOn && props.onColor }}
        className="react-switch-label"
        // htmlFor={'react-switch-new'}
        htmlFor={props.id}
      >
        <span className={'react-switch-button'} />
      </label>
    </>
  );
};

export default Switch;
