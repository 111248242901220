import { api } from './index';
const resource = '/auth';

export const login = async (data) => {
  try {
    const response = await api.post(`${resource}/login`, data);
    const siteId = response.data.site_id;
    if(!siteId) return {user: response.data, site: null}
    const siteInfo  = await api.get(`/sites/${siteId}`);
    return {user: response.data, site: siteInfo.data}
  } catch (error) {
    throw new Error(error.message);
  }
};

export const forgotPassword = async (data) => {
  try {
    const response = await api.post(`${resource}/forgot-password`, data);
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    const errorStatus = error.response?.status;
    throw { message: errorMessage, status: errorStatus };
  }
};
