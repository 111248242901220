import { useCallback, useEffect, useState } from 'react';
import { Button } from '@shopify/polaris';
import { BoxSelect, CheckCircle } from 'lucide-react';
import { Modal } from 'antd';
import Canvas from './polygon-tool/Canvas';
import { deepEqual } from '../utils';
import { useDispatch, useSelector } from 'react-redux';
import { setScenarioFormData } from '../store/slices/scenarioSlice';

const ToConfigure = () => {
  return (
    <div className="flex justify-between items-center">
      <span className="mr-1">Config</span>
      <span className="font-semibold">
        <BoxSelect color="black" size={14} />
      </span>
    </div>
  );
};

const Configured = () => {
  return (
    <div className="flex justify-between items-center">
      <span className="mr-1 text-[#38ab07]">Configured</span>
      <span className="font-semibold">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="16px" height="16px">
          <path
            fill="#38ab07"
            stroke="#38ab07"
            strokeWidth="2"
            d="M 11 4 C 7.101563 4 4 7.101563 4 11 L 4 39 C 4 42.898438 7.101563 46 11 46 L 39 46 C 42.898438 46 46 42.898438 46 39 L 46 15 L 44 17.3125 L 44 39 C 44 41.800781 41.800781 44 39 44 L 11 44 C 8.199219 44 6 41.800781 6 39 L 6 11 C 6 8.199219 8.199219 6 11 6 L 37.40625 6 L 39 4 Z M 43.25 7.75 L 23.90625 30.5625 L 15.78125 22.96875 L 14.40625 24.4375 L 23.3125 32.71875 L 24.09375 33.4375 L 24.75 32.65625 L 44.75 9.03125 Z"
          />
        </svg>
      </span>
    </div>
  );
};

const ViewIfPreviewAvailable = ({ camera, addOrRemoveCamera, isCameraChosen, reset }) => (
  <div className="relative h-32 w-full cursor-pointer bg-green-500 rounded-t-md hover:opacity-50">
    <img
      onClick={() => addOrRemoveCamera(camera)}
      className={`absolute inset-0 h-full w-full rounded-t-md ${isCameraChosen() ? 'opacity-50' : ''}`}
      alt={`${camera.name}'s Feed`}
      src={camera.thumbnail_url}
    />
    {isCameraChosen() && (
      <div
        onClick={() => {
          addOrRemoveCamera(camera);
          reset();
        }}
        className="absolute inset-0 flex justify-center items-center">
        <CheckCircle size={32} color="white" />
      </div>
    )}
  </div>
);

const ViewIfPreviewNotAvailable = ({ addOrRemoveCamera, camera, isCameraChosen, reset }) => (
  <div
    onClick={() => {
      addOrRemoveCamera(camera);
    }}
    className="relative bg-black h-32 grid place-items-center rounded-t-md hover:opacity-50 cursor-pointer">
    <div>
      <p className="text-white font-semibold -mt-6">Preview Unavailable</p>
    </div>
    {isCameraChosen() && (
      <div
        onClick={() => {
          addOrRemoveCamera(camera);
          reset();
        }}
        className="absolute inset-0 flex justify-center items-center bg-green-500 opacity-50">
        <CheckCircle size={32} color="white" />
      </div>
    )}
  </div>
);

const CameraB64 = ({ camera, chosenCameras, addOrRemoveCamera, edit = false }) => {
  const [openModal, setOpenModal] = useState(false);
  const [polygons, setPolygons] = useState([[]]);
  const [isPolygonCompleteArr, setIsPolygonCompleteArr] = useState([false]);
  const [currentPolygonIdx, setCurrentPolygonIdx] = useState(0);

  const [disablePolygonSubmission, setDisablePolygonSubmission] = useState(true)



  // State to manage modal size
  const [modalSize, setModalSize] = useState({
    width: window.innerWidth * 0.9,
    height: window.innerHeight * 0.9
  });

  // Update modal size based on window size
  useEffect(() => {
    const handleResize = () => {
      setModalSize({
        width: window.innerWidth * 0.9,
        height: window.innerHeight * 0.9
      });
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const { scenarioFormData: formData, chosenScenario: scenario } = useSelector(
    (state) => state.scenario
  );
  const dispatch = useDispatch();

  const checkIfPolygonsAreConfigured = (cameraName) => {
    if (!(cameraName in formData.polygonPointsPerCamera)) return false;
    if (formData.polygonPointsPerCamera[cameraName].length === 0) return false;
    if (formData.polygonPointsPerCamera[cameraName].every((polygon) => polygon.length === 0))
      return false;
    return true;
  };

  useEffect(() => {
    dispatch(
      setScenarioFormData({
        ...formData,
        polygonPointsPerCamera: { ...formData.polygonPointsPerCamera, [camera.name]: polygons }
      })
    );
  }, [isPolygonCompleteArr]);

  const reset = () => {
    setPolygons([[]]);
    setIsPolygonCompleteArr([false]);
    setCurrentPolygonIdx(0);
  };

  const addNewZone = () => {
    if (polygons.length === 1 && polygons[0].length === 0) return;
    setPolygons([...polygons, []]);
    setIsPolygonCompleteArr([...isPolygonCompleteArr, false]);
    setCurrentPolygonIdx(polygons.length);
  };

  useEffect(() => {
    if (
      edit &&
      formData.polygonPointsPerCamera[camera.name] &&
      formData.polygonPointsPerCamera[camera.name].length > 0
    ) {
      let isPolygonCompleteArrTemp = []
      setPolygons([...formData.polygonPointsPerCamera[camera.name], []]);
      if(formData.polygonPointsPerCamera[camera.name].length === 0) {
        // SHOULD NOT HAPPEN
        setCurrentPolygonIdx(0);
        isPolygonCompleteArrTemp = [false];
      } else if(formData.polygonPointsPerCamera[camera.name].length === 1 && formData.polygonPointsPerCamera[camera.name][0].length === 0){ 
        // NO POLYGONS CONFIGURED YET
        setCurrentPolygonIdx(0);
        isPolygonCompleteArrTemp = [false];
      } else {
        // ONE OR MORE POLYGONS CONFIGURED
        setCurrentPolygonIdx(formData.polygonPointsPerCamera[camera.name].length);
        isPolygonCompleteArrTemp = [...formData.polygonPointsPerCamera[camera.name].map(() => true), false];
      }
      setIsPolygonCompleteArr(isPolygonCompleteArrTemp);
    }
  }, [edit]);

  const isCameraChosen = useCallback(() => {
    const isChosen = chosenCameras.some((cam) => deepEqual(cam, camera));
    return isChosen;
  }, [chosenCameras]);

  const showModal = () => {
    setOpenModal(true);
  };

  const addCameraAndCloseModal = () => {
    addOrRemoveCamera(camera, 'ADD');
    setOpenModal(false);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  return (
    <div className="h-auto w-auto border rounded-md">
      {camera.thumbnail_url ? (
        <ViewIfPreviewAvailable
          camera={camera}
          addOrRemoveCamera={addOrRemoveCamera}
          reset={reset}
          isCameraChosen={isCameraChosen}
        />
      ) : (
        <ViewIfPreviewNotAvailable
          reset={reset}
          isCameraChosen={isCameraChosen}
          addOrRemoveCamera={addOrRemoveCamera}
          camera={camera}
        />
      )}

      <div className="flex justify-between px-2 py-2 items-center">
        <h5 className="text-md font-semibold">{camera.name}</h5>
        <Button variant="secondary" disabled={!camera?.thumbnail_url} onClick={showModal}>
          {checkIfPolygonsAreConfigured(camera.name) ? <Configured /> : <ToConfigure />}
        </Button>
      </div>
      <Modal
        closable={false}
        title={
          <div className="flex justify-between">
            <h3 className="font-semibold">{camera.name}</h3>
            <div className="">
              <span className="mx-4">
                <Button variant="secondary" onClick={reset}>
                  Reset
                </Button>
              </span>
              <span>
                <Button variant="primary" onClick={addNewZone}>
                  Add New Zone
                </Button>
              </span>
            </div>
          </div>
        }
        open={openModal}
        onOk={addCameraAndCloseModal}
        onCancel={closeModal}
        centered
        width={modalSize.width}
        footer={[
          <div className="inline mr-2" key="back">
            <Button variant="secondary" onClick={closeModal}>
              Cancel
            </Button>
          </div>,
          <div className="inline mr-2" key="submit">
            <Button disabled={disablePolygonSubmission} variant="primary" onClick={addCameraAndCloseModal}>
              Submit
            </Button>
          </div>
        ]}>
        <Canvas
          camera={camera}
          imageSrc={camera.thumbnail_url}
          minWorker={scenario && scenario.name === 'min-workers'}
          maxWorker={scenario && scenario.name === 'max-workers'}
          polygons={polygons}
          setPolygons={setPolygons}
          isPolygonCompleteArr={isPolygonCompleteArr}
          setIsPolygonCompleteArr={setIsPolygonCompleteArr}
          currentPolygonIdx={currentPolygonIdx}
          setCurrentPolygonIdx={setCurrentPolygonIdx}
          reset={reset}
          addNewZone={addNewZone}
          setDisableSubmit={setDisablePolygonSubmission}
        />
      </Modal>
    </div>
  );
};

export default CameraB64;
