import { Divider, RangeSlider, Select, TextField } from '@shopify/polaris';
import { Tooltip } from 'antd';
import { Info } from 'lucide-react';
import { useSelector } from 'react-redux';

const Step3 = ({ options, formData, setFormData }) => {
  const { chosenScenario: scenario } = useSelector((state) => state.scenario);
  return (
    <div className="mt-16 mx-6">
      <div className="my-6 flex sm:flex-col lg:flex-row justify-between lg:items-center">
        <div className="flex items-center">
          <h6 className="semibold mb-2 mr-1">Model Version</h6>
          <span className="mb-2">
            <Tooltip title="Choose the model version. Newer versions may improve detection accuracy and speed.">
              <Info size={14} />
            </Tooltip>
          </span>
        </div>
        <div className="w-2/4">
          <Select
            labelHidden
            options={options}
            onChange={() => {}}
          />
        </div>
      </div>
      <Divider />
      <div className="my-6 flex sm:flex-col lg:flex-row justify-between lg:items-center">
        <div className="flex items-center">
          <h6 className="semibold mb-2 mr-1">Model Confidence</h6>
          <span className="mb-2">
            <Tooltip title="Set confidence level. Higher levels reduce false positives but might miss some events.">
              <Info size={14} />
            </Tooltip>
          </span>
        </div>
        <div className="w-2/4">
          <RangeSlider
            labelHidden
            value={formData.modelConfidence}
            output
            onChange={(newValue) => setFormData({ ...formData, modelConfidence: newValue })}
            helpText={'Model Confidence: ' + formData.modelConfidence + '%'}
          />
        </div>
      </div>
      <Divider />
      <div className="my-6 flex sm:flex-col lg:flex-row justify-between lg:items-center">
        <div className="flex items-center">
          <h6 className="semibold mb-2 mr-1">Duration of Detection</h6>
          <span className="mb-2">
            <Tooltip title="Minimum event duration (in seconds) to trigger a notification. Prevents alerts for brief, irrelevant events.">
              <Info size={14} />
            </Tooltip>
          </span>
        </div>
        <div className="w-2/4">
          <RangeSlider
            labelHidden
            value={formData.detectionDuration}
            helpText={formData.detectionDuration + ' seconds'}
            output
            onChange={(newValue) => setFormData({ ...formData, detectionDuration: newValue })}
            min={1}
            max={15}
          />
        </div>
      </div>
      <Divider />
      <div className="my-6 flex sm:flex-col lg:flex-row justify-between lg:items-center">
        <div className="flex items-center">
          <h6 className="semibold mb-2 mr-1">Hysteresis Time</h6>
          <span className="mb-2">
            <Tooltip title="Cool-down period (in minutes) before reporting a new event of the same type to avoid repeated notifications.">
              <Info size={14} />
            </Tooltip>
          </span>
        </div>
        <div className="w-2/4">
          <RangeSlider
            labelHidden
            value={formData.hysteresisTime}
            helpText={formData.hysteresisTime + ' minutes'}
            output
            onChange={(newValue) => setFormData({ ...formData, hysteresisTime: newValue })}
            min={0}
            max={10}
          />
        </div>
      </div>
      {scenario.name === 'min-workers' && (
        <div>
          <Divider />
          <div className="my-6 flex sm:flex-col lg:flex-row justify-between lg:items-center">
            <div className="flex items-center">
              <h6 className="semibold mb-2 mr-1">Min Workers</h6>
              <span className="mb-2">
                <Tooltip title="The minimum number of workers required in a zone.">
                  <Info size={14} />
                </Tooltip>
              </span>
            </div>
            <div className="w-2/4">
            <TextField
                label=""
                type="text" 
                value={formData.minWorkerCount}
                inputMode="numeric" 
                pattern="[0-9]*" 
                onChange={(event) => {
                  const input = event.replace(/[^0-9]/g, '');
                  if (input !== formData.minWorkerCount) {
                    setFormData({ ...formData, minWorkerCount: input });
                  }
                }}
              />
            </div>
          </div>
        </div>
      )}
      {(scenario.name === 'max-workers' || scenario.name === 'occupancy-monitoring') && (
        <div>
          <Divider />
          <div className="my-6 flex sm:flex-col lg:flex-row justify-between lg:items-center">
            <div className="flex items-center">
              <h6 className="semibold mb-2 mr-1">Max Workers</h6>
              <span className="mb-2">
                <Tooltip title="The maximum number of workers allowed in a zone.">
                  <Info size={14} />
                </Tooltip>
              </span>
            </div>
            <div className="w-2/4">
              <TextField
                label=""
                type="text" 
                value={formData.maxWorkerCount}
                inputMode="numeric" 
                pattern="[0-9]*" 
                onChange={(event) => {
                  const input = event.replace(/[^0-9]/g, '');
                  if (input !== formData.maxWorkerCount) {
                    setFormData({ ...formData, maxWorkerCount: input });
                  }
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Step3;
