/* eslint-disable no-unused-vars */
import { Icon, LegacyCard, Spinner, Tabs } from '@shopify/polaris';
import { PlusMinor } from '@shopify/polaris-icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import {
  deleteUser,
  getAllUsers,
  inviteUser,
  reinviteUser,
  resetPassword,
  updateUser
} from '../../api/users.api';
import { getCountryPhoneCodeMap, getTimezonesForSelect } from '../../data/timezones';
// import { useSearchParams } from 'react-router-dom';
import AddUserModal from '../../components/AddUserModal';
import EditUserModal from '../../components/EditUserModal';
import { notification } from 'antd';
import GraphicDeleteModal from '../../components/GraphicDeleteModal';
import UserManagement from './UserManagement';
import NotificationGroups from './NotificationGroups';
import {
  createNewNotificationGroupWithUsers,
  getSimpleNotificationGroups
} from '../../api/notification-groups.api';
import AddNotificationGroupUser from '../../components/AddNotificationGroupUser';
import { useSelector } from 'react-redux';

const timezones = getTimezonesForSelect();
const countryCodes = getCountryPhoneCodeMap();

const Settings = () => {
  const [api, contextHolder] = notification.useNotification();

  const [roles, setRoles] = useState([]);
  const [sites, setSites] = useState([]);

  const [selected, setSelected] = useState(0);
  const [addUserForm, setAddUserForm] = useState({
    name: '',
    username: '',
    email: '',
    phone: '',
    timezone: timezones[0].value,
    role: null,
    site: null
  });
  const [addUserErrors, setAddUserErrors] = useState({
    invalidEmailFormat: false,
    invalidFieldsError: false,
    failed: false,
    existingEmail: false,
    existingUsername: false
  });

  const [editUserForm, setEditUserForm] = useState({
    user_id: '',
    name: '',
    username: '',
    email: '',
    phone: '',
    timezone: timezones[0].value,
    role: null,
    site: null
  });
  const [editUserErrors, setEditUserErrors] = useState({
    invalidEmailFormat: false,
    invalidFieldsError: false,
    failed: false,
    existingEmail: false,
    existingUsername: false
  });

  const [notificationUserGroupForm, setNotificationUserGroupForm] = useState({
    groupName: '',
    emailList: [],
    emailInputValue: '',
    permissionEnabled: true
  });

  const [notificationUserGroupErrors, setNotificationUserGroupErrors] = useState({
    invalidFieldsError: false,
    failed: false
  });

  // for add user modal
  const [open, setOpen] = useState(false);

  // for edit user modal
  const [openEdit, setOpenEdit] = useState(false);

  // for delete confirmation modal
  const [showDelete, setShowDelete] = useState(false);

  // for notification user group modal
  const [showNotificationGroup, setShowNotificationGroup] = useState(false);

  // for notification group list modal
  const handleAddUserFieldChange = (value, field) => {
    if (field === 'timezone') return setAddUserForm({ ...addUserForm, timezone: value });
    if (field === 'role') return setAddUserForm({ ...addUserForm, role: value });
    if (field === 'site') return setAddUserForm({ ...addUserForm, site: value });
    if (field === 'phone') {
      if (value.includes('.')) return;
      if (isNaN(value)) return;
      if (value.length > 10) return;
    }
    if (field !== 'phone' && value.length > 100) return;
    setAddUserForm({ ...addUserForm, [field]: value });
    if (field === 'username') {
      if (data.some((user) => user.username === value && !user.is_deleted)) {
        setAddUserErrors({ ...addUserErrors, existingUsername: true });
      } else {
        setAddUserErrors({ ...addUserErrors, existingUsername: false });
      }
    }
    if (field === 'email') {
      if (value.length > 100) return;
      if (data.some((user) => user.email === value)) {
        setAddUserErrors({ ...addUserErrors, existingEmail: true });
      } else {
        setAddUserErrors({ ...addUserErrors, existingEmail: false });
      }
    }
  };

  const handleEditUserFieldChange = (value, field) => {
    if (field === 'timezone') return setEditUserForm({ ...editUserForm, timezone: value });
    if (field === 'role') return setEditUserForm({ ...editUserForm, role: value });
    if (field === 'site') return setEditUserForm({ ...editUserForm, site: value });
    if (field === 'phone') {
      if (value.includes('.')) return;
      if (isNaN(value)) return;
      if (value.length > 10) return;
    }
    if (field !== 'phone' && value.length > 100) return;
    setEditUserForm({ ...editUserForm, [field]: value });
  };

  const handleTabChange = useCallback((selectedTabIndex) => setSelected(selectedTabIndex), []);

  const resetAddUserForm = () => {
    setAddUserForm({
      name: '',
      username: '',
      email: '',
      phone: '',
      timezone: timezones[0].value,
      role: roles[0].value,
      site: sites[0].value
    });
    setAddUserErrors({
      invalidEmailFormat: false,
      invalidFieldsError: false,
      failed: false,
      existingEmail: false,
      existingUsername: false
    });
  };

  const resetEditUserForm = () => {
    setEditUserForm({
      user_id: '',
      name: '',
      username: '',
      email: '',
      phone: '',
      timezone: timezones[0].value,
      role: roles[0].value,
      site: sites[0].value
    });
    setEditUserErrors({
      invalidEmailFormat: false,
      invalidFieldsError: false,
      failed: false,
      existingEmail: false,
      existingUsername: false
    });
  };

  const resetNotificationUserGroupForm = () => {
    setNotificationUserGroupForm({
      groupName: '',
      emailList: [],
      emailInputValue: '',
      permissionEnabled: true
    });
    setNotificationUserGroupErrors({
      invalidFieldsError: false,
      failed: false
    });
  };

  const handleOk = () => {
    const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    if (!emailRegex.test(addUserForm.email)) {
      setAddUserErrors({ ...addUserErrors, invalidEmailFormat: true });
      return;
    }

    if (
      !addUserForm.name ||
      !addUserForm.username ||
      !addUserForm.email ||
      !addUserForm.phone ||
      isNaN(addUserForm.phone) ||
      addUserErrors.existingEmail ||
      addUserErrors.existingUsername
    ) {
      setAddUserErrors({ ...addUserErrors, invalidFieldsError: true });
      return;
    }

    mutate(addUserForm);
  };

  const handleEditOk = () => {
    const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    if (!emailRegex.test(editUserForm.email)) {
      setEditUserErrors({ ...editUserErrors, invalidEmailFormat: true });
      return;
    }

    if (
      !editUserForm.name ||
      !editUserForm.username ||
      !editUserForm.email ||
      !editUserForm.phone ||
      !emailRegex.test(editUserForm.email) ||
      isNaN(editUserForm.phone) ||
      editUserErrors.existingEmail ||
      editUserErrors.existingUsername
    ) {
      setEditUserErrors({ ...editUserErrors, invalidFieldsError: true });
      return;
    }
    updateUserApi(editUserForm);
  };

  const handleNotificationGroupOk = () => {
    if (!notificationUserGroupForm.groupName || notificationUserGroupForm.emailList.length === 0) {
      setNotificationUserGroupErrors({ ...notificationUserGroupErrors, invalidFieldsError: true });
      return;
    }
    createGroupApi({
      groupName: notificationUserGroupForm.groupName,
      emailList: notificationUserGroupForm.emailList,
      users: data
    });
  };

  const handleCancel = () => {
    setOpen(false);
    resetAddUserForm();
  };

  const handleEditCancel = () => {
    setOpenEdit(false);
    resetEditUserForm();
  };

  const handleDeleteOk = () => {
    deleteUserApi(editUserForm.username);
  };

  const handleDeleteCancel = () => {
    setShowDelete(false);
    resetEditUserForm();
  };

  const { mutate: deleteUserApi, isPending: isDeletePending } = useMutation({
    mutationFn: (username) => deleteUser(username),
    onError: () => {
      setEditUserErrors({ ...editUserErrors, failed: true });
      api.error({
        message: `Unable to perform action. Please contact support@visionify.ai`,
        placement: 'bottomRight'
      });
    },
    onSuccess: () => {
      api.success({ message: 'User deleted successfully', placement: 'bottomRight' });
      refetch();
      setShowDelete(false);
      resetEditUserForm();
    }
  });

  const { mutate: createGroupApi, isPending: isCreateGroupPending } = useMutation({
    mutationFn: (groupData) => createNewNotificationGroupWithUsers(groupData),
    onError: (error) => {
      const errorMessage = error.message || 'An unexpected error occurred.';
      setNotificationUserGroupErrors({ ...notificationUserGroupErrors, failed: true });
      api.error({ message: errorMessage });
    },
    onSuccess: () => {
      setShowNotificationGroup(false);
      resetNotificationUserGroupForm();
      refetchNotificationGroups();
      api.success({
        message: `Notification group created successfully`,
        placement: 'bottomRight'
      });
    }
  });

  const { data, isLoading, refetch, error } = useQuery({
    queryKey: ['users'],
    queryFn: () => getAllUsers()
  });

  const {
    data: allNotificationGroups,
    isLoading: isLoadingNotificationGroups,
    isError: isNotificationGroupError,
    refetch: refetchNotificationGroups
  } = useQuery({
    queryKey: ['notificationGroups'],
    queryFn: () => getSimpleNotificationGroups()
  });

  const { mutate, isPending } = useMutation({
    mutationFn: (newUser) => inviteUser(newUser),
    onError: (error) => {
      const errorMessage = error.message || 'An unexpected error occurred.';
      api.error({ message: errorMessage });
      setAddUserErrors({ ...addUserErrors, failed: true });
    },
    onSuccess: () => {
      setOpen(false);
      refetch();
      resetAddUserForm();
    }
  });

  const { mutate: reinviteUserApi } = useMutation({
    mutationFn: (username) => reinviteUser(username),
    onError: () => {
      setEditUserErrors({ ...editUserErrors, failed: true });
      api.error({
        message: `Unable to perform action. Please contact support@visionify.ai`,
        placement: 'bottomRight'
      });
    },
    onSuccess: () => {
      refetch();
      setOpenEdit(false);
      api.success({
        message: `Success`,
        placement: 'bottomRight'
      });
    }
  });

  const { mutate: updateUserApi, isPending: isUpdatePending } = useMutation({
    mutationFn: (userData) => updateUser(userData),
    onError: () => {
      setEditUserErrors({ ...editUserErrors, failed: true });
      api.error({
        message: `Unable to perform action. Please contact support@visionify.ai`,
        placement: 'bottomRight'
      });
    },
    onSuccess: () => {
      refetch();
      setOpenEdit(false);
      resetEditUserForm();
      api.success({
        message: `Updated User Information`,
        placement: 'bottomRight'
      });
    }
  });

  const { mutate: resetPasswordApi } = useMutation({
    mutationFn: (userData) => resetPassword(userData),
    onError: () => {
      setEditUserErrors({ ...editUserErrors, failed: true });
      api.error({
        message: `Unable to perform action. Please contact support@visionify.ai`,
        placement: 'bottomRight'
      });
    },
    onSuccess: () => {
      refetch();
      setOpenEdit(false);
      api.success({
        message: `Success`,
        placement: 'bottomRight'
      });
    }
  });

  const { roles: rolesList } = useSelector((state) => state.app);
  const { sitesAvailable: sitesList } = useSelector((state) => state.company);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    let initialRole = null;
    let initialSite = null;

    if (rolesList && rolesList.length > 0) {
      const rolesArr = rolesList
        .filter((r) => r.name !== 'site-maintainer')
        .map((role) => ({
          label: role.name
            .split('-')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' '),
          value: role.name
            .split('-')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ')
        }));
      setRoles(rolesArr);
      initialRole = rolesArr[0].value;
    }

    if (sitesList && sitesList.length > 0) {
      const sitesArr = sitesList.map((site) => ({
        label: site.name,
        value: site.name
      }));
      setSites(sitesArr);
      initialSite = sitesArr[0].value;
    }

    // Set the initial values for addUserForm and editUserForm only if they are available
    if (initialRole && initialSite) {
      setAddUserForm((prevForm) => ({
        ...prevForm,
        role: initialRole,
        site: initialSite
      }));
      setEditUserForm((prevForm) => ({
        ...prevForm,
        role: initialRole,
        site: initialSite
      }));
    }
  }, [rolesList, sitesList]); // Dependency array includes rolesList and sitesList to re-run effect when they change

  const tabs = [
    {
      id: 'user-management-1',
      content: 'User Management',
      accessibilityLabel: 'User Management',
      panelID: 'user-management-1'
    },
    /* Code Commented to Not Shrow 'Notification Group Management' tab, since Notification Group(s) has been deprecated */
    // {
    //   id: 'notification-group-management-1',
    //   content: 'Notification Group Management',
    //   accessibilityLabel: 'Notification Group Management',
    //   panelID: 'notification-group-management-1'
    // }
  ];

  const setTabActionTitle = useCallback((idx) => {
    switch (idx) {
      case 0:
        return 'Add User';
      case 1:
        return 'Add Group';
      default:
        return 'Add User';
    }
  }, []);

  const setTabContent = (idx) => {
    switch (idx) {
      case 0:
        return (
          <UserManagement
            data={data}
            setOpenEdit={setOpenEdit}
            setEditUserForm={setEditUserForm}
            error={error}
          />
        );
      case 1:
        return (
          <NotificationGroups
            data={allNotificationGroups}
            error={isNotificationGroupError}
            users={data}
          />
        );
      default:
        return <></>;
    }
  };

  if (isLoading || isLoadingNotificationGroups) {
    return (
      <div className="flex justify-center">
        <Spinner size="large" />
      </div>
    );
  }

  return (
    <div className="flex flex-col mx-4 my-4">
      {contextHolder}
      <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
        <LegacyCard.Section
          title={tabs[selected].content}
          actions={
            user.role_id === 6 // TODO: Change global-user access to a dynamic methodology
              ? null
              : [
                  {
                    content: setTabActionTitle(selected),
                    icon: <Icon source={PlusMinor} />,
                    onAction() {
                      if (selected === 0) {
                        setOpen(true);
                      } else {
                        setShowNotificationGroup(true);
                      }
                    }
                  }
                ]
          }>
          {isLoading ? (
            <div className="flex justify-center">
              <Spinner size="large" />
            </div>
          ) : (
            setTabContent(selected)
          )}
        </LegacyCard.Section>
      </Tabs>
      <AddNotificationGroupUser
        form={notificationUserGroupForm}
        setForm={setNotificationUserGroupForm}
        isPending={isCreateGroupPending}
        errors={notificationUserGroupErrors}
        open={showNotificationGroup}
        handleOk={handleNotificationGroupOk}
        handleCancel={() => {
          setShowNotificationGroup(false);
          resetNotificationUserGroupForm(false);
        }}
      />
      <AddUserModal
        handleAddUserFieldChange={handleAddUserFieldChange}
        open={open}
        handleOk={handleOk}
        handleCancel={handleCancel}
        addUserForm={addUserForm}
        addUserErrors={addUserErrors}
        isPending={isPending}
        timezones={timezones}
        roles={roles}
        sites={sites}
        countryCodes={countryCodes}
      />
      <EditUserModal
        handleEditUserFieldChange={handleEditUserFieldChange}
        open={openEdit}
        setOpen={setOpenEdit}
        setShowDelete={setShowDelete}
        editUserForm={editUserForm}
        editUserErrors={editUserErrors}
        reinviteUser={reinviteUserApi}
        resetPassword={resetPasswordApi}
        handleOk={handleEditOk}
        handleCancel={handleEditCancel}
        isPending={isUpdatePending}
        timezones={timezones}
        roles={roles}
        sites={sites}
        countryCodes={countryCodes}
      />
      <GraphicDeleteModal
        open={showDelete}
        title={'Delete User'}
        text={'Are you sure you want to delete this user?'}
        handleOk={handleDeleteOk}
        handleCancel={handleDeleteCancel}
        isPending={isDeletePending}
      />
    </div>
  );
};

export default Settings;
