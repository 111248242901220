import { Badge, Button, DataTable, Icon, Spinner, Tooltip } from '@shopify/polaris';
import { EditMajor, CircleInformationMajor } from '@shopify/polaris-icons';
import { useSelector } from 'react-redux';

const truncateUrl = (url) => {
  return url.length > 50 ? `${url.slice(0, 50)}...` : url;
};

const constructRows = (setShowTeamsWebhookModal, setChosenTeamsWebhookData, data, roleId) => {
  const rows = [];
  data.map((webhook) => {
    rows.push([
      webhook.id,
      webhook.name,
      <div key={webhook.id} style={{ display: 'flex', alignItems: 'center' }}>
        <span>{truncateUrl(webhook.webhook_url)}</span>
        {webhook.webhook_url.length > 50 && (
          <Tooltip persistOnClick content={webhook.webhook_url}>
            <Icon source={CircleInformationMajor} tone="base" />
          </Tooltip>
        )}
      </div>,
      <Badge key={webhook.id} tone={webhook.status ? 'success' : 'critical'}>
        {webhook.status ? 'Active' : 'Inactive'}
      </Badge>,
      <Button
        key={`button-${webhook.id}`}
        disabled={!(roleId === 1 || roleId===2)}
        onClick={() => {
          setShowTeamsWebhookModal(true);
          setChosenTeamsWebhookData(webhook);
        }}>
        <Icon source={EditMajor} />
      </Button>
    ]);
  });
  return rows;
};

const MsTeamsWebhookInfo = ({
  setShowTeamsWebhookModal,
  msTeamsWebhookCallback,
  setChosenTeamsWebhookData
}) => {
  const {user} = useSelector(state => state.auth)
  if (msTeamsWebhookCallback.isLoading) {
    return (
      <div>
        <Spinner size="small" />
      </div>
    );
  }
  return (
    <>
      <DataTable
        columnContentTypes={['text', 'text', 'text', 'text', 'text']}
        headings={['ID', 'Name', 'Webhook URL', 'Status', 'Action']}
        hasZebraStripingOnData
        rows={constructRows(
          setShowTeamsWebhookModal,
          setChosenTeamsWebhookData,
          msTeamsWebhookCallback.data,
          user.role_id
        )}
      />
    </>
  );
};

export default MsTeamsWebhookInfo;
