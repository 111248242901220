import React, { useState, useCallback } from 'react';
import { Popover, DatePicker, Button } from '@shopify/polaris';
import { dateToWordsTz } from '../utils';
import { useSelector } from 'react-redux';

const DatePickerPopover = ({ selectedDates, setSelectedDates }) => {
  const { user } = useSelector((state) => state.auth);
  const [popoverActive, setPopoverActive] = useState(false);
  const [month, setMonth] = useState(
    new Date(selectedDates.start)?.getMonth() - 1 || new Date().getMonth() - 1
  );
  const [year, setYear] = useState(
    new Date(selectedDates.start)?.getFullYear() || new Date().getFullYear()
  );

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    []
  );

  const getButtonContent = () => {
    if (selectedDates.start && selectedDates.end) {
      return `${dateToWordsTz(selectedDates.start, user?.timezone ?? 'UTC', true)} to ${dateToWordsTz(
        selectedDates.end,
        user?.timezone ?? 'UTC',
        true
      )}`;
    }
    return 'Select Date Range';
  };

  const handleDateChange = useCallback((value) => {
    setSelectedDates(value);
  }, []);

  const handleMonthChange = useCallback((month, year) => {
    setMonth(month);
    setYear(year);
  }, []);

  const activator = (
    <Button onClick={togglePopoverActive} disclosure fullWidth textAlign="left" size="large">
      {getButtonContent()}
    </Button>
  );

  return (
    <div>
      <label>Date</label>

      <Popover active={popoverActive} activator={activator} onClose={togglePopoverActive} sectioned>
        <DatePicker
          month={month}
          year={year}
          onMonthChange={handleMonthChange}
          selected={(() => ({
            start: new Date(selectedDates.start),
            end: new Date(selectedDates.end)
          }))()}
          onChange={handleDateChange}
          multiMonth
          allowRange
          disableDatesAfter={new Date()}
        />
      </Popover>
    </div>
  );
};

export default DatePickerPopover;
