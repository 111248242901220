import { Button, Spinner } from '@shopify/polaris';
import { Modal, notification } from 'antd';
import { useEffect, useState } from 'react';
import IntegrationForm from './IntegrationForm';
import { useMutation } from '@tanstack/react-query';
import { isProtocolValid } from '../../../utils';
import { createIntegration, updateIntegration } from '../../../api/integrations.api';


const IntegrationModal = ({
  isOpen,
  handleCancel = () => {},
  chosenIntegrationData,
  setChosenIntegrationData,
  setIsOpen,
  integrationCallback
}) => {
  const [api, contextHolder] = notification.useNotification();

  const [formData, setFormData] = useState({
    name: '',
    webhook_url: '',
    status: '1'
  });

  useEffect(() => {
    if (chosenIntegrationData) {
      setFormData({
        name: chosenIntegrationData.name,
        webhook_url: chosenIntegrationData.webhook_url,
        status: chosenIntegrationData.status ? '1' : '0'
      });
    } else {
      setFormData({
        name: '',
        webhook_url: '',
        status: '1'
      });
    }
  }, [chosenIntegrationData, isOpen]);

  const { mutate, isPending: isModifyingIntegration } = useMutation({
    mutationFn: chosenIntegrationData ? updateIntegration : createIntegration,
    onSuccess: () => {
      setIsOpen(false);
      setChosenIntegrationData(null);
      integrationCallback.refetch();
    },
    onError: (error) => {
      api.error({
        message: error?.message || 'Something went wrong'
      });
    }
  });

  const handleOk = () => {
    if (!formData.name || !formData.webhook_url || !formData.status === undefined) {
      return api.warning({
        message: 'Make sure to fill all required fields'
      });
    }
    if (!isProtocolValid(formData.webhook_url)) {
      return api.warning({
        message: 'Invalid Webhook URL'
      });
    }
    mutate({
      id: chosenIntegrationData?.id,
      data: {
        ...formData,
        name: formData.name,
        webhook_url: formData.webhook_url,
        status: formData.status === '1'
      }
    });
  };

  return (
    <>
      {contextHolder}
      <Modal
        title={chosenIntegrationData ? 'Edit Integration' : 'Add New Integration'}
        open={isOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button variant="secondary" onClick={handleCancel} key="cancel">
            Cancel
          </Button>,
          <Button variant="primary" onClick={handleOk} disabled={isModifyingIntegration} key="submit">
            <div className="flex items-center">
              Save
              {isModifyingIntegration && (
                <span className="ml-2">
                  <Spinner size="small" />
                </span>
              )}
            </div>
          </Button>
        ]}
      >
        <form>
          <IntegrationForm formData={formData} setFormData={setFormData} />
        </form>
      </Modal>
    </>
  );
};

export default IntegrationModal;
