import axios from 'axios';
import { api } from './index';
const resource = '/scenario';

export const getScenarios = async () => {
  try {
    const response = await axios.get(
      'https://docsvisionify.blob.core.windows.net/docs-images/scenarios-beta.json'
    );
    if (!response.data) {
      console.log({ error: 'unable to fetch scenarios' });
      return {};
    }
    return response.data;
  } catch (e) {
    console.log({ error: e });
    return {};
  }
};

export const applyScenarioForCamera = async ({
  cameraName,
  scenario,
  cameraId,
  payload,
  appliedScenarioId,
  scenarioTitle,
  eventBasedZones
}) => {
  try {
    let additionalParams = '';
    if (appliedScenarioId) {
      additionalParams = `&appliedScenarioId=${appliedScenarioId}`;
    }
    const response = await api.post(
      `${resource}/apply-scenario?cameraId=${cameraId}&scenario=${scenario}&cameraName=${cameraName}${additionalParams}&scenarioTitle=${scenarioTitle}&eventBasedZones=${eventBasedZones ? 'true' : 'false'}`,
      { ...payload }
    );
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAppliedScenarioByAppliedScenarioId = async (appliedScenarioId) => {
  try {
    const response = await api.get(`${resource}/get-applied-scenario?id=${appliedScenarioId}`);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAppliedScenariosByCameraId = async (cameraId) => {
  try {
    const response = await api.get(`${resource}/get-applied-scenarios/${cameraId}`);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getScenarioByName = async (name) => {
  try {
    const response = await axios.get(
      `https://docsvisionify.blob.core.windows.net/docs-images/each_scenario_details/${name}.json`
    );
    if (!response.data) {
      console.log({ error: 'unable to fetch scenarios' });
      return {};
    }
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
};

export const applyScenario = async (formData, scenario) => {
  try {
    const payload = {};
    payload.cameras = formData.cameras.map((camera) => camera.name);
    const scenarioInfo = {
      name: scenario.title,
      notification: true,
      isEmail: true,
      scenario_metadata: null,
      isContact: true,
      /* Code Commented to Pass groupId array as NULL, since option to choose Notification Group(s) has been deprecated */
      // groupsId: formData.selectedNotificationGroups.map((group) => {
      //   return group.id;
      // }) ?? [1],
      groupsId: null,
      maxPeopleCount: null,
      events: Object.keys(formData.events).filter((event) => formData.events[event]),
      event_push: [],
      modelConfidence: formData.modelConfidence,
      consecutiveFrames: 50,
      recordings: true,
      recordingTime: formData.detectionDuration,
      recording_event_level: formData.eventLevel,
      active: true,
      runStatus: 'started',
      description: '',
      model_name: scenario.name,
      ml_version: formData.modelVersion,
      model_url: scenario.models.latest.model_url
    };
    scenarioInfo.scenario_metadata = {
      bboxes: Object.keys(formData.polygonPointsPerCamera)
        .filter(
          (camera) =>
            formData.polygonPointsPerCamera[camera].length > 0 &&
            formData.polygonPointsPerCamera[camera][0].length > 0
        )
        .map((c) => {
          return { name: c, bbox: [formData.polygonPointsPerCamera[c]] };
        }),
      startTime: formData.startTime,
      endTime: formData.endTime,
      timeZone: 'America/Los_Angeles',
      imageDimensions: formData.imageDimensionsPerCamera,
      event_level: formData.eventLevel[0],
      detectionDuration: formData.detectionDuration,
      hysteresisTime: formData.hysteresisTime
    };
    if (scenario.name === 'min-workers') {
      scenarioInfo.scenario_metadata.minWorkerCount = formData.minWorkerCount;
    }
    if (scenario.name === 'max-workers') {
      scenarioInfo.scenario_metadata.maxWorkerCount = formData.maxWorkerCount;
    }

    payload.scenario = scenarioInfo;
    const response = await api.post(`${resource}`, payload);
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    const errorStatus = error.response?.status;
    throw { message: errorMessage, status: errorStatus };
  }
};

export const getScenariosForCamera = async (cameraName) => {
  try {
    const response = await api.get(`${resource}/camera/${cameraName}`);
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
};

export const startStopScenarioForCamera = async (id, isActive) => {
  try {
    const response = await api.put(`${resource}/start-stop/${id}`, {
      is_active: isActive ? 1 : 0
    });
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
};

export const deleteScenarioForCamera = async (cameraName, scenarioName) => {
  try {
    const response = await api.delete(`${resource}/${cameraName}/${scenarioName}`);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getScenarioTitleToKeyMappings = async () => {
  try {
    const response = await axios.get(
      'https://docsvisionify.blob.core.windows.net/docs-images/scenarios.json'
    );
    if (!response.data) {
      console.log({ error: 'unable to fetch scenarios' });
      return {};
    }

    const scenarioTitleToKeyMappings = response.data.scenarios.reduce((acc, scenario) => {
      acc[scenario.title] = scenario.name;
      return acc;
    }, {});
    return scenarioTitleToKeyMappings;
  } catch (e) {
    console.log({ errorScen: e });
    throw new Error(e);
  }
};

export const getAppliedScenarioForCamera = async (cameraId, scenarioName) => {
  try {
    const response = await api.get(`${resource}/get-applied-scenario/${cameraId}/${scenarioName}`);
    if (response.data?.success) {
      return response.data.data;
    }
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
};
